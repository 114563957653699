import React from 'react';
import TextEditor from '../../../shared/TextEditor/TextEditor';
import { useDispatch } from 'react-redux';
import { updateItem } from '../../../../actions/reportActions';

const EditorToolbarWhenFocused = ({ text, item }) => {
  let dispatch = useDispatch();

  const handleEditorChange = html => {
    let temp = document.createElement('div');
    temp.innerHTML = html;
    let parsedText = temp.textContent || temp.innerText || '';
    dispatch(updateItem(item.id, { ...item, htmlContent: html, textContent: parsedText }));
  };
  return (
    <div onMouseDown={e => e.stopPropagation()}>
      <TextEditor input={{ value: text, onChange: handleEditorChange }} toolbarOnFocus colorPicker placeholder='Start writing here...' />
    </div>
  );
};
const EditableText = props => {
  const item = props.item;
  const isView = props.mode === 'view';

  const showHeaderLevel = item => {
    if (!item.textContent) return '';

    let textFormmated = item.textContent.split('\n');

    textFormmated = textFormmated.map((text, index) => {
      switch (item.properties.headingLevel) {
        case 1:
          text = text ? `<h1>${text}</h1>` : '<p></p>';
          break;
        case 2:
          text = text ? `<h2>${text}</h2>` : '<p></p>';
          break;
        case 3:
          text = text ? `<h3>${text}</h3>` : '<p></p>';
          break;
        case 4:
          text = text ? `<h4>${text}</h4>` : '<p></p>';
          break;
        case 5:
          text = text ? `<h5>${text}</h5>` : '<p></p>';
          break;
        case 6:
          text = `<h6>${text}</h6>`;
          break;
        default:
          text = `<p>${text}</p>`;
      }
      return text;
    });

    return textFormmated.join('') || item.htmlContent;
  };
  let formattedText = '';
  if (item.properties.isHeading) {
    formattedText = showHeaderLevel(item);
    console.log(formattedText);
    formattedText = formattedText.replace(/<br>/g, '&nbsp;');
  } else {
    formattedText = item.htmlContent.replace(/<br>/g, '&nbsp;');
  }

  if (item.isPageNumber) {
    formattedText = formattedText.replace(/{{page_num}}/g, `${props.pageNum}`);
    formattedText = formattedText.replace(/>(\d+)</g, `>${props.pageNum}<`);
  }

  return (
    <div className={!props.isActive ? 'p-1 editable-text-component' : 'p-1'}>
      {!isView && props.isActive && <EditorToolbarWhenFocused item={item} text={formattedText} />}
      {(isView || !props.isActive) && <div dangerouslySetInnerHTML={{ __html: formattedText }}></div>}
    </div>
  );
};
export default EditableText;
