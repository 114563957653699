import React from 'react';
import { Field } from 'redux-form';
import FileDrop from 'SharedComponent/FileDrop';
import Translations from 'Translations/file.json';

class FileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      name: null,
    };
  }

  displayImage = e => {
    var self = this;
    var target = e.target;
    var file, reader;

    if (target.files && target.files.length) {
      file = target.files[0];
      reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onloadend = function () {
        self.props.input.onChange(reader.result);
        self.setState({
          file: reader.result,
        });
      };
    }
  };

  clearFile = () => {
    this.props.input.onChange(null);
    this.setState({
      file: null,
    });
  };

  render() {
    return (
      <div className='image-upload-container'>
        <FileDrop
          url={this.state.file}
          classNames='p-4 cursor-pointer'
          styles={{
            height: '100%',
            width: '100%',
            borderRadius: '4px',
            border: '1px solid #e6e8ea',
          }}
          placeholder={Translations[this.props.language]['placeholder.text']}
          onUpload={file => {
            this.props.input.onChange(file);
            this.setState({ file });
          }}
        />
      </div>
    );
  }
}

export default FileComponent;
