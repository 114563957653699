//@ts-ignore
import { Notifier } from 'SharedComponent/Notifier';
interface IntegrationsOauth2Props {
  integrationUrl: string;
  onSuccess(code: string, userState?: string): void;
  onSlackSuccess?(status: string): void;
  onFailure(message: string): void;
}
interface CalendarIntegrationProps {
  // tslint:disable-next-line:no-any
  gapi: any;
  onCalendarSuccess(code: string): void;
}
// tslint:disable-next-line:no-any
export function calendarAuth(props: CalendarIntegrationProps): void {
  //@ts-ignore
  const gapi = window.gapi;
  gapi.load('auth2', () => {
    const auth2 = gapi.auth2.getAuthInstance({
      client_id: process.env.GOOGLE_CALENDAR_CLIENT_ID,
      scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
      discovery_docs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
      fetch_basic_profile: false,
      approval_prompt: 'force',
      access_type: 'offline',
      flowName: 'GeneralOAuthFlow',
    });
    if (auth2) {
      auth2.then(_ => {
        _.grantOfflineAccess().then(({ code }) => {
          props.onCalendarSuccess(code);
        });
      });
    } else {
      gapi.auth2
        .init({
          client_id: process.env.GOOGLE_CALENDAR_CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events',
          discovery_docs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
          fetch_basic_profile: false,
          approval_prompt: 'force',
          access_type: 'offline',
          flowName: 'GeneralOAuthFlow',
        })
        .then(_ => {
          _.grantOfflineAccess().then(({ code }) => {
            props.onCalendarSuccess(code);
          });
        });
    }
  });
}
export function integrationAuth(props: IntegrationsOauth2Props): void {
  const openPopup = () => {
    const url = props.integrationUrl;

    return window.open(url, '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, fullscreen=yes');
  };
  const polling = popup => {
    const pollingInterval = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(pollingInterval);
        props.onFailure('Popup has been closed by user');
      }

      const closeDialog = () => {
        clearInterval(pollingInterval);
        popup.close();
      };

      try {
        if (popup.location.hostname.includes('localhost') || popup.location.hostname.includes('app.pentugram')) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const oauthCode = query.get('code');
            const userState = query.get('state');
            const slackStatus = query.get('status');
            closeDialog();
            if (oauthCode) {
              return props.onSuccess(oauthCode, userState);
            } else if (slackStatus) {
              Notifier({
                type: 'success',
                title: 'Slack connected',
                message: 'Slack connected successfully',
              });
            } else if (props.onFailure) {
              props.onFailure(query.get('error'));
            }
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    }, 500);
  };
  polling(openPopup());
}
