import { routerReducer as routing } from 'react-router-redux';
import { combineReducers, Reducer } from 'redux';
import { reducer as form } from 'redux-form';

import auth from '../../reducers/authReducer';
import anim from '../../reducers/animReducer';
import dashboard from '../../reducers/dashboardReducer';
import pipeline from '../../reducers/pipelineReducer';
import reports from '../../reducers/reportsReducer';
import metrics from '../../reducers/metricsReducer';
import contacts from '../../reducers/contactsReducer';
import calendar from '../../reducers/calendarReducer';
import company from '../../reducers/companyReducer';
import users from '../../reducers/usersReducer';
import billing from '../../reducers/billingReducer';
import pipelines from '../../reducers/pipelinesReducer';
import notifs from '../../reducers/notificationsReducer';
import errors from '../../reducers/errorsReducer';
import header from '../../reducers/headerReducer';
import tasks from '../../reducers/tasksReducer';
import emailTemplates from '../../reducers/emailTemplatesReducer';
import utils from '../../reducers/utilsReducer';
import formBuilder from '../../reducers/formsReducer';
import integrationSettings from '../../reducers/integrationSettingsReducer';
import chat from '../../reducers/chatReducer';
import funds from '../../reducers/fundsReducers';

import { modalReducer } from '../shared/components/Modal/state/modal.reducer';
import { drawerReducer } from '../shared/components/Drawer/state/drawer.reducer';
import { routeReducer } from './routes/router.reducer';
import { pipelineReducer } from '../modules/pipeline/state/pipeline.reducer';
import { summaryReducer } from '../modules/dashboard/board/summary-log/state/summary.reducer';
import { dashboardKpisReducer } from '../modules/dashboard/kpis/state/kpis.reducer';
import { dashboardNewsReducer } from '../modules/dashboard/board/news/state/news.reducer';
import { integrationReducer } from '../modules/settings/integrations/state/integration.reducer';
import { dashboardTasksReducer } from '../modules/dashboard/board/dashboard-tasks/state/task.reducer';
import { tasksReducer } from '../modules/tasks/state/tasks.reducer';

import { InternalState } from './v2.type';
import { permissionsReducer } from '../modules/settings/permissions/state/permissions.reducer';
import { meReducer } from './me/me.reducer';

const v2Reducer: Reducer<InternalState> = combineReducers({
  drawer: drawerReducer,
  router: routeReducer,
  modal: modalReducer,
  pipeline: pipelineReducer,
  summaryLog: summaryReducer,
  dashboardKpis: dashboardKpisReducer,
  dashboardNews: dashboardNewsReducer,
  dashboardTasks: dashboardTasksReducer,
  integration: integrationReducer, // combineReducers
  permissions: permissionsReducer,
  tasks: tasksReducer,
  me: meReducer,
});

export const createSagaReducer = combineReducers({
  v2: v2Reducer,
  auth,
  anim,
  dashboard,
  pipeline,
  routing,
  reports,
  metrics,
  contacts,
  errors,
  notifs,
  header,
  calendar,
  tasks,
  integrationSettings,
  settings: combineReducers({
    company,
    users,
    billing,
    pipelines,
    emailTemplates,
    formBuilder,
    funds,
  }),
  form,
  utils,
  chat,
});
