import React from 'react';
import { ModalIdEnum } from '../../../core/enums/entity-ids/modal-id.enum';
import { Modal } from '../Modal/modal.component';
import { Button } from 'antd';
import './PipelineOpportunitiesUpgradeModal.scss';
import { closeModal } from '../Modal/state/modal.actions';
import { useDispatch } from 'react-redux';
import { browserHistory } from 'react-router';

function PipelineOpportunitiesUpgradeModal(props) {
  const dispatch = useDispatch();

  return (
    <Modal
      modalId={ModalIdEnum.pipelineOpportunitiesUpgrade}
      title='Pipeline Opportunities'
      width='40rem'
      hasTitle={true}
      onCancel={props.onClose}
      extraClasses='pipeline-opportunities-upgrade-modal'
    >
      <div className='pipeline-opportunities-upgrade-modal-container'>
        <div className='pipeline-opportunities-upgrade-modal-image'></div>
        <div className='pipeline-opportunities-upgrade-modal-content'>
          <div className='pipeline-opportunities-upgrade-modal-content__badge'>New Feature!</div>
          <div>
            <h3>Invest with Confidence</h3>
            <p>
              We are excited to introduce a new investment feature exclusive to our Gold Plan members. Upgrade now to explore and take advantage of this incredible opportunity!
            </p>
          </div>
        </div>
        <div className='pipeline-opportunities-upgrade-modal-footer'>
          <Button
            type='default'
            size='large'
            onClick={() => {
              props.onClose();
              dispatch(closeModal());
            }}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            style={{ backgroundColor: '#3070CC', borderColor: '#3070CC' }}
            size='large'
            onClick={() => {
              props.onClose();
              browserHistory.push('/settings/billing');
              dispatch(closeModal());
            }}
          >
            Upgrade to Gold Plan
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PipelineOpportunitiesUpgradeModal;
