import React from 'react';
import { Icon, Modal } from 'antd';
import './PackageUpgradeModal.scss';

function PackageUpgradeModal(props) {
  return (
    <Modal className='package-upgrade-modal' visible={props.isVisible} footer={null} closable={false}>
      <div className='package-upgrade-modal__content'>Your plan has been successfully upgraded to the gold plan.</div>
      <Icon type='check-circle' className='package-upgrade-modal__check-circle' />
      <div className='package-upgrade-modal__note'>Note* the page will be refreshed in 5 seconds</div>
    </Modal>
  );
}

export default PackageUpgradeModal;
