import { dispatchAlert } from './notificationActions';
import { Notifier } from 'SharedComponent/Notifier';
import moment from 'moment';

import omit from 'lodash/omit';
import chunk from 'lodash/chunk';
import unset from 'lodash/unset';
import api from './api';
import apiV2 from './apiv2';
import { at } from 'Utils/objectUtils';
import objectToFormData from 'Utils/objToFormData';
import { Mixpanel } from 'App/app';
import { openModal } from '../v2.0/shared/components/Modal/state/modal.actions';
import { ModalIdEnum } from '../v2.0/core/enums/entity-ids/modal-id.enum';

export const COLLAPSE_ALL_STAGES = 'pipeline:collaspe:all:stages';
export const LOADING_DEFAULT_PIPELINE = 'pipeline:default:loading';
export const LOADING_DEFAULT_BODY = 'pipeline:default:body:loading';
export const DEFAULT_BODY_PIPELINE_CLEARED = 'pipeline:default:body:clean';
export const DEFAULT_PIPELINE_LOADED = 'pipeline:default:loaded';
export const DEFAULT_HEADER_PIPELINE_LOADED = 'pipeline:default:header:loaded';
export const DEFAULT_BODY_PIPELINE_LOADED = 'pipeline:default:body:loaded';
export const LOADING_REOPEN_PIPELINES = 'pipeline:reopen:loaded';
export const EXTRA_PIPELINE_LOADED = 'pipeline:EXTRA_PIPELINE_LOADED:loaded';
export const MOVE_CARD = 'MOVE_CARD';
export const MOVE_LIST = 'MOVE_LIST';
export const TOGGLE_DRAGGING = 'TOGGLE_DRAGGING';
export const LOADED_PIPELINES = 'settings:pipelines:loaded';
export const LOADING_PIPELINES = 'settings:pipelines:loading';
export const LOADING_PIPELINES_FAILED = 'settings:pipelines:loading:failed';
export const PIPELINE_DELETED = 'settings:pipeline:deleted';
export const LIST_PIPELINE_DELETED = 'settings:pipeline:list:deleted';
export const LIST_PIPELINE_UPDATED = 'settings:pipeline:list:updated';
export const DEAL_LOADING = 'deal:loading';
export const VOTE_LOADING = 'deal:vote:loading';
export const DEAL_LOADED = 'deal:loaded:success';
export const INVESTMENTS_LOADED = 'deal:loaded:investments';
export const DEAL_LOADED_FAILED = 'deal:loaded:failed';
export const PIPELINE_LIST_LOADED = 'settings:pipeline:list:loaded';
export const PIPELINE_CREATED = 'settings:pipeline:created';
export const EDITING_PIPELINE = 'settings:pipeline:editing';
export const PIPELINE_UPDATED = 'settings:pipeline:updated';
export const PIPELINE_TYPE_UPDATED = 'settings:pipeline:type:updated';
export const FILTER_BY = 'pipeline:filter:deal:by';
export const UPDATE_ALL_FILTERS = 'pipeline:filters:update:all';
export const UPDATE_FILTER = 'pipeline:filters:update:one';
export const LOADED_PIPELINE_STAGES = 'pipeline:stages:loaded';
export const LOADED_DEAL_PIPELINE_STAGES = 'deal:pipeline:stages:loaded';
export const STAGES_LOADING = 'deal:pipeline:stages:loading';
export const DEAL_CREATED = 'pipeline:deal:created';
export const UPDATE_LIVE_DEAL = 'pipeline:deal:UPDATE_LIVE_DEAL';
export const CLEAN_PIPELINE = 'pipeline:clean';
export const DEAL_NOTIFICATIONS_LOADED = 'deal:notifications:loaded';
export const DEAL_NOTIFICATIONS_LOADING = 'deal:notifications:loading';
export const TASKS_DEAL_LOADED = 'deal:tasks:loaded';
export const TASK_DEAL_CREATED = 'deal:tasks:created';
export const TASK_DEAL_UPDATED = 'deal:tasks:updated';
export const TASK_DEAL_DELETED = 'deal:tasks:deleted';
export const EDITING_DEAL_TASK = 'deal:tasks:editing';
export const DEAL_UPDATED = 'pipeline:deal:updated';
export const ALL_DEAL_LIST_LOADED = 'deal:allList:loaded';
export const NOTES_DEAL_LOADED = 'deal:notes:loaded';
export const NOTE_DEAL_CREATED = 'deal:notes:created';
export const NOTE_DEAL_UPDATED = 'deal:notes:updated';
export const NOTE_DEAL_LOADING = 'deal:notes:loading';
export const EDITING_NOTE = 'deal:notes:editing';
export const MEETINGS_DEAL_LOADED = 'deal:meetings:loaded';
export const MEETING_DEAL_CREATED = 'deal:meetings:created';
export const FILES_DEAL_LOADED = 'deal:files:loaded';
export const FILE_DEAL_UPLOADIND = 'deal:files:uploading';
export const FILE_DEAL_CREATED = 'deal:files:created';
export const MULTI_FILES_DEAL_CREATED = 'deal:multi:files:created';
export const FILE_DEAL_DELETED = 'deal:files:deleted';
export const SELECTED_FILE_IDS = 'deal:files:SELECTED_FILE_IDS';
export const DEAL_FOLDER_UPDATED = 'deal:files:folder:updated';
export const REMOVE_BULK_FOLDERS = 'deal:files:remove:bulk';
export const FILE_VIEW_UPDATED = 'deal:files:FILE_VIEW_UPDATED';
export const EMAILS_DEAL_LOADED = 'deal:emails:loaded';
export const EDITING_DEAL_EMAIL = 'deal:email:editing';
export const EMAIL_DEAL_CREATED = 'deal:email:created';
export const DEAL_STATUS_UPDATED = 'deal:status:updated';
export const DEAL_RATED = 'deal:rating:successfully';
export const DELETE_ROUND = 'deal:round:deleted';
export const ROUND_UPDATED = 'deal:round:updated';
export const INVESTMENTS_CREATED = 'deal:round:created';
export const SET_DEAL_NAVIGATION = 'deal:navigation:get';
export const DEAL_NAVIGATION_LOADING = 'deal:navigation:loading';
export const INVESTORS_LOADED = 'deal:investor:loaded';
export const INVESTOR_DELETED = 'deal:investor:deleted';
export const INVESTOR_CREATED = 'deal:investor:created';
export const INVESTOR_UPDATED = 'deal:investor:updated';
export const ALL_INVESTORS_LOADED = 'deal:all:investors:loaded';
export const DEAL_RATE_CREATED = 'deal:rate:created';
export const DEAL_CASH_OUT_CREATED = 'deal:cash-out:created';
export const DEAL_RATE_UPDATED = 'deal:rate:updated';
export const DEAL_CASH_OUT_UPDATED = 'deal:cash:out:updated';
export const DEAL_RATE_DELETED = 'deal:rate:deleted';
export const DEAL_CASH_OUT_DELETED = 'deal:cash:out:deleted';
export const DEAL_RATES_LOADED = 'deal:rates:loaded';
export const DEAL_CASHOUT_LOADED = 'deal:cash:loaded';
export const PORTFOLIO_DASH_STATS_LOADED = 'portfolio:stats:loaded';
export const PORTFOLIO_DASH_STATS_LOADING = 'portfolio:stats:loading';
export const PORTFOLIO_DASH_STATS_EXPORT_LOADED = 'portfolio:stats:export:loaded';
export const PORTFOLIO_DASH_STATS_EXPORT_LOADING = 'portfolio:stats:export:loading';
export const ANGEL_INVESTORS_LOADED = 'pipeline:angel-investor:linked';
export const IS_PIPELINE_SYSTEM = 'pipeline:is_system';

function normalizeTask(task) {
  let guests = task.people ? task.people.filter(it => !it.is_user).map(it => it.value) : [];
  let attendees = task.people ? task.people.filter(it => it.is_user).map(it => it.value) : [];
  let due_date = task.due_date ? moment(task.due_date).tz(task.timezone).format('L HH:mm') : null;

  return {
    ...omit(task, ['people']),
    guests: JSON.stringify(guests),
    attendees: JSON.stringify(attendees),
    due_date,
  };
}

function normalizeEmail(email) {
  return {
    ...omit(email, ['template']),
    files: email.files || [],
    text: extractContent(email.html),
    to: checkEmails(email.to),
    cc: checkEmails(email.cc),
    bcc: checkEmails(email.bcc),
    reply_to: email.reply_to,
    is_reply: email.is_reply,
  };
}

function checkEmails(emails) {
  if (typeof emails === 'string') return emails;
  else if (emails) {
    const result = emails.filter(it => validateEmail(it.value)).map(op => op.value);
    return result.join(',');
  } else return emails;
}

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function extractContent(s) {
  var span = document.createElement('span');
  span.innerHTML = s;
  return span.textContent || span.innerText;
}

function normalizeMeeting(meeting) {
  const today = new Date(meeting.from);
  today.setHours(0, 0, 0, 0);
  return {
    ...omit(meeting, ['date', 'from', 'to', 'start']),
    eventype_id: 2,
    start_date: meeting.start && meeting.from ? Math.trunc((meeting.start.getTime() + (meeting.from.getTime() - today.getTime())) / 1000) : null,
    end_date: meeting.start && meeting.from ? Math.trunc((meeting.start.getTime() + meeting.from.getTime() - today.getTime()) / 1000) : null,
    attendees: {
      ids: meeting.attendees ? meeting.attendees.split(',') : [],
    },
  };
}

function normalizeDeal(data) {
  const deal = {
    ...omit(data, ['assignee', 'countryName', 'currencyName']),
    country: data.countryName ? data.countryName.country_id : null,
    currency: data.currencyName ? data.currencyName.currency_id : null,
    activity: data.domain ? data.domain.domain_id : null,
  };
  return data.assignee
    ? {
        ...deal,
        assigned_to: data.assignee.id,
      }
    : deal;
}

function errorMessage(error) {
  let err = error;
  if (error.response) {
    err = error.response;
  }
  const data = err.data ? err.data : err.toString();
  let errorBody = data.internalCode ? data.internalCode : data.message ? data.message : data;
  if (errorBody.length > 80) {
    errorBody = errorBody.substring(0, 80);
  }
  return errorBody;
}

function formatPipelines(list) {
  return list.map(pipeline => pipeline.pipeline_id);
}

function dataURItoBlob(dataURI) {
  let binary = atob(dataURI.split(',')[1]);
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

export function collapseAll(val) {
  return dispatch => {
    dispatch({ type: COLLAPSE_ALL_STAGES, payload: val });
  };
}

export function loadPipeline(id) {
  return dispatch => {
    if (id == null) {
      dispatch({ type: DEFAULT_PIPELINE_LOADED, payload: null });
    } else {
      dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: true });
      api
        .get(`/pipelines/${id}?expand=stages.deals`)
        .then(({ data }) => {
          dispatch({ type: DEFAULT_PIPELINE_LOADED, payload: data });
        })
        .catch(error =>
          Notifier({
            type: 'error',
            title: errorMessage(error),
            message: 'Please try again',
          }),
        );
    }
  };
}

export function loadPipelineDealsByStatus(pipeline_id, status) {
  return dispatch => {
    const statusId = status ? status : 1;
    dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: true });
    api
      .get(`/pipelines/${pipeline_id}/deals/${statusId}`)
      .then(({ data }) => {
        dispatch({ type: DEFAULT_PIPELINE_LOADED, payload: data });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
  };
}

let timer_ids = [];
function clearTimeIds() {
  timer_ids.forEach(i => clearTimeout(i));
}

export function clearTimeLoop() {
  return dispatch => {
    clearTimeIds();
  };
}

function normalizeFilters(filters) {
  if (filters.custom && filters.custom.fromTo) {
    const newFilters = {
      ...filters,
      custom: {
        ...filters.custom,
        fromDate: filters.custom.fromTo[0],
        toDate: filters.custom.fromTo[1],
      },
    };
    unset(newFilters, 'custom.fromTo');
    return newFilters;
  } else return filters;
}

export function loadPipelineStagesDetails(pipeline_id, filters = {}) {
  return dispatch => {
    api.get(`/pipelines/${pipeline_id}`).then(({ data }) => {
      dispatch({ type: DEFAULT_HEADER_PIPELINE_LOADED, payload: data });
    });
  };
}

export function loadPipelineHeaderDetails(pipeline_id, filters = {}) {
  clearTimeIds();
  return dispatch => {
    dispatch({ type: DEFAULT_BODY_PIPELINE_CLEARED });
    dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: true });
    api
      .post(`/pipelines/${pipeline_id}`, normalizeFilters(filters))
      .then(({ data }) => {
        dispatch({ type: DEFAULT_HEADER_PIPELINE_LOADED, payload: data });
        dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: false });
        dispatch({ type: LOADING_DEFAULT_BODY, payload: true, percent: 0 });
        dispatch({ type: IS_PIPELINE_SYSTEM, payload: data.is_system_pipeline });
        api
          .post(`/pipelines/${pipeline_id}/deals`, normalizeFilters(filters))
          .then(({ data }) => {
            const chunked = chunk(data, 100);
            for (let i = 0; i < chunked.length; i++) {
              (i => {
                let timer = setTimeout(() => {
                  dispatch({ type: DEFAULT_BODY_PIPELINE_LOADED, payload: chunked[i] });
                  dispatch({ type: LOADING_DEFAULT_BODY, payload: true, percent: Math.trunc(((i + 1) / chunked.length) * 100) });
                  if (i + 1 == chunked.length) dispatch({ type: LOADING_DEFAULT_BODY, payload: false, percent: 100 });
                }, 1000 * i);
                timer_ids.push(timer);
              })(i);
            }
            if (chunked.length === 0) dispatch({ type: LOADING_DEFAULT_BODY, payload: false, percent: 100 });
          })
          .catch(r => {
            dispatch({ type: LOADING_DEFAULT_BODY, payload: false, percent: 0 });
          });
      })
      .catch(error => {
        dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: false });
      });
  };
}

export function getDealNavigation(id, filters = {}) {
  return dispatch => {
    dispatch({ type: DEAL_NAVIGATION_LOADING });
    api
      .post(`deals/${id}/navigation`, filters)
      .then(({ data }) => {
        dispatch({ type: SET_DEAL_NAVIGATION, payload: data });
      })
      .catch(err => {});
  };
}

export function loadPipelineDealsDetails(pipeline_id, filters = {}) {
  clearTimeIds();
  return dispatch => {
    api
      .post(`/pipelines/${pipeline_id}`, normalizeFilters(filters))
      .then(({ data }) => {
        dispatch({ type: DEFAULT_HEADER_PIPELINE_LOADED, payload: data });
      })
      .catch();
    dispatch({ type: DEFAULT_BODY_PIPELINE_CLEARED });
    dispatch({ type: LOADING_DEFAULT_BODY, payload: true, percent: 0 });
    api
      .post(`/pipelines/${pipeline_id}/deals`, normalizeFilters(filters))
      .then(({ data }) => {
        const chunked = chunk(data, 100);
        for (let i = 0; i < chunked.length; i++) {
          (i => {
            let timer = setTimeout(() => {
              dispatch({ type: DEFAULT_BODY_PIPELINE_LOADED, payload: chunked[i] });
              dispatch({ type: LOADING_DEFAULT_BODY, payload: true, percent: Math.trunc(((i + 1) / chunked.length) * 100) });
              if (i + 1 == chunked.length) dispatch({ type: LOADING_DEFAULT_BODY, payload: false, percent: 100 });
            }, 1000 * i);
            timer_ids.push(timer);
          })(i);
        }
      })
      .catch(r => {
        dispatch({ type: LOADING_DEFAULT_BODY, payload: false, percent: 0 });
      });
  };
}

export function loadPipelineDealsByStatusByCountry(pipeline_id, status, country = 'all') {
  return dispatch => {
    const statusId = status ? status : 1;
    dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: true });
    api
      .get(`/pipelines/${pipeline_id}/deals/${statusId}/country/${country}`)
      .then(({ data }) => {
        dispatch({ type: DEFAULT_PIPELINE_LOADED, payload: data });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
  };
}

export function loadAndUpdatePipeline(id) {
  return dispatch => {
    if (id == null) {
      dispatch({ type: DEFAULT_PIPELINE_LOADED, payload: null });
    } else {
      dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: true });
      api
        .get(`/pipelines/${id}?expand=stages.deals`)
        .then(({ data }) => {
          dispatch({ type: DEFAULT_PIPELINE_LOADED, payload: data });
          api
            .put(`/pipelines/${id}`, { ...data, is_default: 1 })
            .then(({ data }) => {})
            .catch(error => {});
        })
        .catch(error =>
          Notifier({
            type: 'error',
            title: errorMessage(error),
            message: 'Please try again',
          }),
        );
    }
  };
}

export function moveList(lastX, nextX) {
  return dispatch => {
    dispatch({ type: MOVE_LIST, lastX, nextX });
  };
}

export function moveCard(lastX, lastY, nextX, nextY, item, nextStage, isPortfolio) {
  return dispatch => {
    dispatch({ type: MOVE_CARD, lastX, lastY, nextX, nextY, item });
    api
      .put(`/deals/${item.deal_id}/pipeline`, { ...item, stage_id: nextStage.stage_id })
      .then(({ data }) => {
        if (lastX != nextX) {
          if (isPortfolio) {
            api
              .post(`/pipelines/${item.pipeline_id}`, normalizeFilters(localStorage.getItem('PORTFOLIO_FILTERS') || {}))
              .then(({ data }) => {
                if (data.is_system_pipeline && nextStage.name === 'Interested') {
                  dispatch(openModal({ modalId: ModalIdEnum.pipelineOpportunitiesInterested }));
                }
                dispatch({ type: DEFAULT_HEADER_PIPELINE_LOADED, payload: data });
              })
              .catch();
          } else {
            api
              .post(`/pipelines/${item.pipeline_id}`, normalizeFilters(localStorage.getItem('PIPELINE_FILTERS') || {}))
              .then(({ data }) => {
                if (data.is_system_pipeline && nextStage.name === 'Interested') {
                  dispatch(openModal({ modalId: ModalIdEnum.pipelineOpportunitiesInterested }));
                }
                dispatch({ type: DEFAULT_HEADER_PIPELINE_LOADED, payload: data });
              })
              .catch();
          }
        }
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: `Deal ${item.name} to stage ${nextStage.name}`,
          message: `Can not update`,
        });
      });
  };
}

export function toggleDragging(isDragging) {
  return dispatch => {
    dispatch({ type: TOGGLE_DRAGGING, isDragging });
  };
}

export function loadReopenWonPipeines(type) {
  return dispatch => {
    const filter = `filter[is_portfolio]=${type}&fields=pipeline_id,name,is_default`;
    api
      .get(`pipelines?${filter}`)
      .then(({ data }) => {
        dispatch({
          type: LOADING_REOPEN_PIPELINES,
          payload: data.results,
        });
      })
      .catch(error => {});
  };
}

export function loadExtraPipelines() {
  return dispatch => {
    api
      .get(`pipelines?filter[is_portfolio]=0&fields=pipeline_id,name,is_system_pipeline,is_default`)
      .then(({ data }) => {
        dispatch({
          type: EXTRA_PIPELINE_LOADED,
          payload: data.results,
        });
      })
      .catch(error => {});
  };
}

export function loadPipelineTypeList(type) {
  return dispatch => {
    dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: true });
    const filter = type == null ? 'fields=pipeline_id,name,is_system_pipeline,is_default' : `filter[is_portfolio]=${type}&fields=pipeline_id,name,is_system_pipeline,is_default`;
    api
      .get(`pipelines?${filter}`)
      .then(({ data }) => {
        if (!data.results.length) dispatch({ type: LOADING_DEFAULT_PIPELINE, payload: false });
        dispatch({
          type: PIPELINE_LIST_LOADED,
          payload: data.results,
        });
      })
      .catch(error => {});
  };
}

export function dismissListPipeline() {
  return dispatch => {
    dispatch({ type: PIPELINE_LIST_LOADED, payload: [] });
  };
}

export function loadPipelinesSettings(is_portfolio) {
  return dispatch => {
    dispatch({ type: LOADING_PIPELINES });
    api
      .get(`/pipelines/${is_portfolio}/settings`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_PIPELINES,
          payload: data.results,
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_PIPELINES_FAILED });
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        });
      });
  };
}

export function loadPipelineStage(id) {
  return dispatch => {
    if (!id) {
      dispatch({
        type: LOADED_PIPELINE_STAGES,
        payload: [],
      });
    } else {
      dispatch({ type: STAGES_LOADING });
      api
        .get(`/pipelines/${id}/stages`)
        .then(({ data }) => {
          dispatch({
            type: LOADED_PIPELINE_STAGES,
            payload: data.results,
          });
        })
        .catch(error => {});
    }
  };
}

export function updateDeal(deal, closeModal = () => {}, setLoading = null) {
  return dispatch => {
    dispatch({ type: DEAL_LOADING });
    setLoading && setLoading(true);
    api
      .put(`/deals/${deal.deal_id}`, normalizeDeal(deal))
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Success',
          message: `Deal has been successfully updated!`,
        });
        dispatch({
          type: DEAL_UPDATED,
          payload: data,
        });
        setLoading && setLoading(false);
        closeModal();
      })
      .catch(error => {
        dispatch({ type: DEAL_LOADED_FAILED });
        setLoading && setLoading(false);
        Notifier({
          type: 'error',
          title: at(error, 'response.data.message') || 'Please try again',
        });
      });
  };
}

export function deletePipeline(pipeline) {
  return dispatch => {
    api
      .delete(`/pipelines/${pipeline.pipeline_id}`)
      .then(response => {
        dispatch({
          type: PIPELINE_DELETED,
          payload: pipeline.pipeline_id,
        });
        dispatchAlert(dispatch, {
          message: `The pipelines ${pipeline.title} have been successfully deleted.`,
          type: 'danger',
        });
        Mixpanel.track('Pipeline Deleted settings', { pipeline_id: pipeline.pipeline_id });
      })
      .catch(error => {
        dispatch({ LOADING_PIPELINES_FAILED });
        Mixpanel.track('Pipeline Deleted Failed settings', { error: error.response.data.message });
      });
  };
}

export function addPipeline(pipeline, cb) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      api
        .post(`/pipelines`, pipeline)
        .then(({ data }) => {
          resolve(data);
          Notifier({
            type: 'success',
            title: 'Success',
            message: `${pipeline.is_portfolio ? 'Portfolio' : 'Pipeline'} has been successfully created!`,
          });
          dispatch({
            type: PIPELINE_CREATED,
            payload: { ...data, stages: pipeline.stages },
          });
          Mixpanel.track(`${pipeline.is_portfolio ? 'Portfolio' : 'Pipeline'} Created settings`, { pipeline_id: data.pipeline_id });
          if (cb) cb();
        })
        .catch(error => {
          reject(error);
          Mixpanel.track(`${pipeline.is_portfolio ? 'Portfolio' : 'Pipeline'} Created Failed settings`, { error: error.response.data.message });
          Notifier({
            type: 'error',
            title: pipeline.title,
            message: "Couldn't create pipeline. Please try again later.",
          });
        });
    });
  };
}

export function loadDeal(id) {
  if (id == null) {
    return {
      type: DEAL_LOADED,
      payload: null,
    };
  }
  return dispatch => {
    dispatch({ type: DEAL_LOADING });
    api
      .get(`/deals/${id}/info`)
      .then(({ data }) => {
        dispatch({
          type: DEAL_LOADED,
          payload: data,
        });
      })
      .catch(({ response }) => {
        dispatch({ type: DEAL_LOADED_FAILED });
        Notifier({
          type: 'error',
          title: response && response.data && response.data.message,
          message: 'Please try again',
        });
      });
  };
}

export function editingPipeline(pipeline) {
  return dispatch => {
    dispatch({ type: EDITING_PIPELINE, payload: pipeline });
  };
}

export function updatePipelineType(pipeline) {
  return dispatch => {
    api
      .put(`/pipelines/${pipeline.pipeline_id}`, pipeline)
      .then(({ data }) => {
        dispatchAlert(dispatch, {
          message: `The pipelines ${pipeline.name} have been successfully updated.`,
          type: 'info',
        });
        dispatch({ type: PIPELINE_TYPE_UPDATED, payload: pipeline });
      })
      .catch(error => {});
  };
}

export function updatePipeline(pipeline, cb) {
  return dispatch => {
    api
      .put(`/pipelines/${pipeline.pipeline_id}`, pipeline)
      .then(({ data }) => {
        dispatchAlert(dispatch, {
          message: `The pipelines ${pipeline.name} have been successfully updated.`,
          type: 'info',
        });
        dispatch({ type: PIPELINE_UPDATED, payload: data });
        if (cb) cb();
      })
      .catch(error => {});
  };
}

export function filterBy(id, field) {
  return dispatch => {
    dispatch({ type: FILTER_BY, payload: { id, filter: field } });
  };
}

export function updateAllFilters(filters) {
  return dispatch => {
    dispatch({ type: UPDATE_ALL_FILTERS, payload: filters });
  };
}

export function updateFilter(filter, value) {
  return dispatch => {
    dispatch({ type: UPDATE_FILTER, payload: { filter, value } });
  };
}

export function clearPipeline() {
  return dispatch => {
    dispatch({ type: CLEAN_PIPELINE });
  };
}

export function createDeal(deal, modalClose = () => {}, setLoading = null) {
  return dispatch => {
    setLoading && setLoading(true);

    api
      .post('/deals', normalizeDeal(deal))
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: `Deal have been successfully created`,
          message: `${data.name}`,
          redirect: `/deals/${data.deal_id}`,
        });
        setLoading && setLoading(false);
        modalClose();
      })
      .catch(error => {
        setLoading && setLoading(false);
        Notifier({
          type: 'error',
          title: at(error, 'response.data.message') || 'Please try again',
        });
      });
  };
}

export function createPusherDeal(deal) {
  return dispatch => {
    dispatch({ type: DEAL_CREATED, payload: deal });
  };
}

export function updateLiveDeal(deal) {
  return dispatch => {
    dispatch({ type: UPDATE_LIVE_DEAL, payload: deal });
  };
}

export function deleteDeal(deal) {
  return dispatch => {
    api
      .delete(`/deals/${deal.deal_id}`)
      .then(response => {
        browserHistory.push('/pipeline');
      })
      .catch(error => {});
  };
}

export function loadDealNotifications(deal_id) {
  return dispatch => {
    dispatch({ type: DEAL_NOTIFICATIONS_LOADING, payload: true });
    api
      .get(`/deals/${deal_id}/notifications`)
      .then(({ data }) => {
        dispatch({ type: DEAL_NOTIFICATIONS_LOADED, payload: data.reverse() });
      })
      .catch(error => {
        dispatch({ type: DEAL_NOTIFICATIONS_LOADING, payload: false });
      });
  };
}

export function loadTasksDeal(deal_id) {
  return dispatch => {
    api
      .get(`/tasks?filter[deal_id]=${deal_id}&sort=-created_at`)
      .then(({ data }) => {
        dispatch({ type: TASKS_DEAL_LOADED, payload: data.results });
      })
      .then(error => {});
  };
}

export function createTaskDeal(task) {
  const config = {
    config: {
      headers: { 'Content-Type': 'multipart/form-data' },
    },
  };
  const newTask = normalizeTask(task);
  let formData = objectToFormData(newTask);

  return dispatch => {
    api
      .post(`/tasks`, formData, config)
      .then(({ data }) => {
        dispatch({ type: TASK_DEAL_CREATED, payload: data });
        Notifier({
          type: 'success',
          title: 'Task created successfully',
          message: data.title,
        });
      })
      .then(error => {});
  };
}

export function updateTaskDeal(task) {
  const config = {
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  };
  const newTask = normalizeTask(task);
  let oldFile = JSON.stringify(
    task.files.filter(function (file) {
      return file.hasOwnProperty('title');
    }),
  );
  let files = task.files.filter(function (file) {
    return file.hasOwnProperty('key');
  });

  const formattedTask = { ...newTask, oldFile, files };
  let formData = objectToFormData(formattedTask);
  return dispatch => {
    api
      .post(`/tasks/${task.task_id}/update`, formData, config)
      .then(({ data }) => {
        dispatch({ type: TASK_DEAL_UPDATED, payload: data });
        Notifier({
          type: 'success',
          title: 'Task updated successfully',
          message: data.title,
        });
      })
      .then(error => {});
  };
}

export function deleteDealTask(task) {
  return dispatch => {
    api
      .delete(`/tasks/${task.task_id}/delete`)
      .then(({ data }) => {
        dispatch({ type: TASK_DEAL_DELETED, payload: task.task_id });
      })
      .then(error => {});
  };
}

export function deletePipelines(pipelines) {
  return dispatch => {
    const ids = formatPipelines(pipelines);
    api
      .post(`pipelines/deleteall`, { ids })
      .then(({ data }) => {
        dispatchAlert(dispatch, {
          message: data.message,
          type: 'danger',
        });
        dispatch({ type: LIST_PIPELINE_DELETED, payload: ids });
      })
      .then(error => {});
  };
}

export function updatePipelines(data) {
  return dispatch => {
    const ids = formatPipelines(data.pipelines);
    api
      .post(`pipelines/moveall`, { ids, is_portfolio: data.is_portfolio })
      .then(({ data }) => {
        dispatchAlert(dispatch, {
          message: data.message,
          type: 'info',
        });
        dispatch({ type: LIST_PIPELINE_UPDATED, payload: ids });
        Mixpanel.track('Pipeline Moved settings', { pipeline_id: ids });
      })
      .then(error => {
        Mixpanel.track('Pipeline Moved Failed settings', { error: error.response.data.message });
      });
  };
}

export function loadDealList() {
  return dispatch => {
    api
      .get(`deals?fields=name,deal_id,image`)
      .then(({ data }) => {
        dispatch({
          type: ALL_DEAL_LIST_LOADED,
          payload: data.results,
        });
      })
      .then(error => {});
  };
}

export function loadNotesDeal(deal_id, page) {
  return dispatch => {
    if (!deal_id) {
      return dispatch({ type: NOTES_DEAL_LOADED, payload: null });
    }
    api
      .get(`/notes?filter[deal_id]=${deal_id}&sort=-created_at&page=${page}`)
      .then(({ data }) => {
        dispatch({ type: NOTES_DEAL_LOADED, payload: data });
      })
      .then(error => {});
  };
}

export function createNoteDeal(note) {
  return dispatch => {
    dispatch({ type: NOTE_DEAL_LOADING, payload: true });
    const formData = new FormData();
    formData.append('deal_id', note.deal_id);
    formData.append('content', note.content);
    formData.append('angel_investor_share', note.angel_investor_share);
    formData.append('share', note.share);
    (note.user_mention_list || []).forEach((element, __) => {
      formData.append('user_mention_list[]', element);
    });

    (note.files || []).forEach((element, __) => {
      formData.append('note_files[]', element);
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    api
      .post(`/notes`, formData, config)
      .then(({ data }) => {
        dispatch({ type: NOTE_DEAL_LOADING, payload: false });
        dispatch({ type: NOTE_DEAL_CREATED, payload: data });
        Notifier({
          type: 'success',
          title: 'Note created successfully',
          message: '',
        });
      })
      .catch(error => {
        dispatch({ type: NOTE_DEAL_LOADING, payload: false });
        Notifier({
          type: 'error',
          title: 'Unable to create note.',
          message: error.response.data.message,
        });
      });
  };
}

export function updateNoteDeal(note) {
  return dispatch => {
    dispatch({ type: NOTE_DEAL_LOADING, payload: true });
    const formData = new FormData();
    formData.append('deal_id', note.deal_id);
    formData.append('content', note.content ? note.content : null);
    formData.append('angel_investor_share', note.angel_investor_share);
    formData.append('share', note.share);
    (note.user_mention_list || []).forEach((element, __) => {
      formData.append('user_mention_list[]', element);
    });
    (note.files || []).forEach((element, __) => {
      formData.append('note_files[]', element);
    });
    (note.oldFile || []).forEach((element, __) => {
      formData.append('oldFile[]', element.file_id);
    });
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    api
      .put(`/notes/${note.note_id}`, formData, config)
      .then(({ data }) => {
        dispatch({ type: NOTE_DEAL_UPDATED, payload: data });
        dispatch({ type: NOTE_DEAL_LOADING, payload: false });
        Notifier({
          type: 'info',
          title: 'Note updated successfully',
          message: '',
        });
      })
      .catch(error => {
        dispatch({ type: NOTE_DEAL_LOADING, payload: false });
        Notifier({
          type: 'error',
          title: 'Unable to update note.',
          message: error.response.data.message,
        });
      });
  };
}

export function editingNote(note) {
  return dispatch => dispatch({ type: EDITING_NOTE, payload: note });
}

export function shareNote({ share, deal_id, note_id }) {
  return dispatch => {
    api
      .post(`/investors/share/note`, { share, deal_id, note_id })
      .then(({ data }) => {
        dispatch({ type: NOTE_DEAL_UPDATED, payload: data });
        Notifier({
          type: 'info',
          title: share ? 'Note shared with investors' : 'Note unshared',
          message: '',
        });
      })
      .then(error => {});
  };
}

export function loadMeetingsDeal(deal_id) {
  return dispatch => {
    api
      .get(`/events?filter[deal_id]=${deal_id}`)
      .then(({ data }) => {
        dispatch({ type: MEETINGS_DEAL_LOADED, payload: data.results });
      })
      .then(error => {});
  };
}

export function createMeetingDeal(data) {
  return dispatch => {
    api
      .post(`/events`, data)
      .then(({ data }) => {
        dispatch({ type: MEETING_DEAL_CREATED, payload: data });
        Notifier({
          type: 'success',
          title: 'Meeting created successfully',
          message: data.title,
        });
      })
      .then(error => {});
  };
}

export function loadFilesDeal(deal_id, parent) {
  return dispatch => {
    if (!deal_id) {
      return dispatch({ type: FILES_DEAL_LOADED, payload: [], pathFiles: null });
    }
    dispatch({ type: FILE_DEAL_UPLOADIND, payload: true });
    api
      .get(`/files?filter[deal_id]=${deal_id}&filter[parent_id]=${parent.file_id}`)
      .then(({ data }) => {
        dispatch({ type: FILES_DEAL_LOADED, payload: data, pathFiles: parent });
        dispatch({ type: FILE_DEAL_UPLOADIND, payload: false });
      })
      .catch(error => {
        dispatch({ type: FILE_DEAL_UPLOADIND, payload: false });
      });
  };
}

export function combineFolders({ parent_id, ids }) {
  return dispatch => {
    dispatch({ type: FILE_DEAL_UPLOADIND, payload: true });
    api
      .post(`/files/folders`, { parent_id, ids })
      .then(({ data }) => {
        dispatch({ type: REMOVE_BULK_FOLDERS, payload: ids });
        dispatch({ type: FILE_DEAL_UPLOADIND, payload: false });
      })
      .then(error => {
        dispatch({ type: FILE_DEAL_UPLOADIND, payload: false });
      });
  };
}

export function uploadFromDropbox({ files, deal_id, parent_id }) {
  return dispatch => {
    dispatch({ type: FILE_DEAL_UPLOADIND, payload: true });
    api
      .post(`/files/dropbox-import`, { files, deal_id, parent_id })
      .then(({ data }) => {
        dispatch({ type: MULTI_FILES_DEAL_CREATED, payload: data });
        dispatch({ type: FILE_DEAL_UPLOADIND, payload: false });
      })
      .catch(error => dispatch({ type: FILE_DEAL_UPLOADIND, payload: false }));
  };
}

export function uploadFromGoogleDrive({ files, deal_id, parent_id, token }) {
  return dispatch => {
    dispatch({ type: FILE_DEAL_UPLOADIND, payload: true });
    api
      .post(`/files/drive-import`, { files, deal_id, parent_id, token })
      .then(({ data }) => {
        dispatch({ type: MULTI_FILES_DEAL_CREATED, payload: data });
        dispatch({ type: FILE_DEAL_UPLOADIND, payload: false });
      })
      .catch(error => dispatch({ type: FILE_DEAL_UPLOADIND, payload: false }));
  };
}

export function createFileDeal(object, multiple) {
  return dispatch => {
    dispatch({ type: FILE_DEAL_UPLOADIND, payload: true });
    if (multiple) {
      const formData = new FormData();
      let len = object.file.length;
      for (let x = 0; x < len; x++) {
        formData.append('file[]', object.file[x]);
      }
      formData.append('deal_id', object.deal_id);
      formData.append('parent_id', object.parent_id);
      formData.append('is_leaf', 1);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      api
        .post(`/files`, formData, config)
        .then(({ data }) => {
          dispatch({ type: FILE_DEAL_CREATED, payload: data });
          dispatch({ type: FILE_DEAL_UPLOADIND, payload: false });
        })
        .catch(error => dispatch({ type: FILE_DEAL_UPLOADIND, payload: false }));
    } else {
      const formData = new FormData();
      formData.append('file', object.file);
      formData.append('title', object.file.name);
      formData.append('deal_id', object.deal_id);
      formData.append('parent_id', object.parent_id);
      formData.append('is_leaf', 1);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      api
        .post(`/files`, formData, config)
        .then(({ data }) => {
          dispatch({ type: FILE_DEAL_CREATED, payload: data });
          dispatch({ type: FILE_DEAL_UPLOADIND, payload: false });
        })
        .catch(error => dispatch({ type: FILE_DEAL_UPLOADIND, payload: false }));
    }
  };
}

export function createNewFolder(deal_id, parent_id, title) {
  return dispatch => {
    api
      .post(`/files`, { parent_id, title, deal_id, is_leaf: 0 })
      .then(({ data }) => {
        dispatch({ type: FILE_DEAL_CREATED, payload: [data] });
        dispatch({ type: SELECTED_FILE_IDS, payload: [data.file_id] });
      })
      .catch(error => {});
  };
}

export function removeFile(file_id) {
  return dispatch => {
    api
      .delete(`/files/${file_id}`)
      .then(({ data }) => {
        dispatch({ type: FILE_DEAL_DELETED, payload: file_id });
      })
      .then(error => {});
  };
}

export function removeFolders(ids) {
  return dispatch => {
    api
      .post(`/files/folders/delete`, { ids })
      .then(({ data }) => {
        dispatch({ type: REMOVE_BULK_FOLDERS, payload: ids });
      })
      .then(error => {});
  };
}

export function updateViewFile(view) {
  return dispatch => dispatch({ type: FILE_VIEW_UPDATED, payload: view });
}

export function updateFolder(folder) {
  return dispatch => {
    api
      .put(`/files/${folder.file_id}`, { ...folder })
      .then(({ data }) => {
        dispatch({ type: DEAL_FOLDER_UPDATED, payload: data });
        dispatch({ type: SELECTED_FILE_IDS, payload: [data.file_id] });
      })
      .then(error => {});
  };
}

export function onSelectedFilesId(ids = []) {
  return dispatch => dispatch({ type: SELECTED_FILE_IDS, payload: ids });
}

export function loadEmailsDeal(deal_id, page) {
  return dispatch => {
    if (!deal_id) {
      return dispatch({ type: EMAILS_DEAL_LOADED, payload: null });
    }
    api
      .get(`/emails?filter[deal_id]=${deal_id}&sort=-created_at&page=${page}`)
      .then(({ data }) => {
        dispatch({ type: EMAILS_DEAL_LOADED, payload: data });
      })
      .then(error => {});
  };
}

export function sendDealEmail(object) {
  return dispatch => {
    const email = normalizeEmail(object);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    formData.append('bcc', email.bcc ? email.bcc : null);
    formData.append('cc', email.cc ? email.cc : null);
    formData.append('to', email.to ? email.to : null);
    formData.append('subject', email.subject);
    if (email.reply_to && email.is_reply) {
      formData.append('is_reply', email.is_reply);
      formData.append('reply_to', email.reply_to);
    }
    formData.append('text', email.text);
    formData.append('html', email.html);
    formData.append('deal_id', email.deal_id);
    email.files.forEach((element, idx) => {
      formData.append('files[]', element);
    });
    api
      .post(`/emails`, formData, config)
      .then(({ data }) => {
        dispatch({ type: EMAIL_DEAL_CREATED, payload: data });
        Notifier({
          type: 'success',
          title: 'Your email has been sent.',
          message: 'To ' + data.to,
        });
      })
      .then(error => {});
  };
}

export function editingEmail(email) {
  return dispatch => {
    dispatch({ type: EDITING_DEAL_EMAIL, payload: email });
  };
}

export function editingDealTask(task) {
  return dispatch => {
    dispatch({ type: EDITING_DEAL_TASK, payload: task });
  };
}

export function exportDealPdf(deal) {
  return dispatch => {
    api
      .get(`/deals/${deal.deal_id}/dealpdf`, { responseType: 'arraybuffer' })
      .then(({ data }) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const a = document.createElement('a');
        const fileURL = window.URL.createObjectURL(file);
        a.href = fileURL;
        a.download = `${deal.pipeline.name}-${deal.name}`;
        a.click();
      })
      .catch(error => {});
  };
}

export function lostDeal(deal) {
  return dispatch => {
    dispatch({ type: DEAL_LOADING });
    api
      .post(`deals/${deal.deal_id}/lost`, deal)
      .then(({ data }) => {
        dispatch({ type: DEAL_STATUS_UPDATED, payload: data });
      })
      .then(error => {});
  };
}

export function wonDeal(deal) {
  return dispatch => {
    dispatch({ type: DEAL_LOADING });
    api
      .post(`deals/${deal.deal_id}/won`, deal)
      .then(({ data }) => {
        dispatch({ type: DEAL_STATUS_UPDATED, payload: data });
      })
      .then(error => {});
  };
}

export function reopenDeal(deal) {
  return dispatch => {
    dispatch({ type: DEAL_LOADING });
    api
      .post(`deals/${deal.deal_id}/reopen`, deal)
      .then(({ data }) => {
        dispatch({ type: DEAL_STATUS_UPDATED, payload: data });
      })
      .then(error => {});
  };
}

export function rateDeal(rate, deal_id) {
  return dispatch => {
    api
      .post(`/deals/rate`, { rate, deal_id })
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Thanks for the rating',
          message: `${rate} Stars`,
        });
        dispatch({
          type: DEAL_RATED,
          payload: data,
        });
      })
      .then(error => {});
  };
}

export function enableVoting(values, cb) {
  return dispatch => {
    dispatch({ type: VOTE_LOADING });
    api
      .post(`/deal-vote/enable`, { ...values })
      .then(({ data }) => {
        dispatch({ type: DEAL_LOADED, payload: data });
        cb();
      })
      .catch(({ response }) => {
        dispatch({ type: DEAL_LOADED_FAILED });
        Notifier({
          type: 'error',
          title: response && response.data && response.data.message,
          message: 'Please try again',
        });
        cb();
      });
  };
}
export function disableVoting(deal_id) {
  return dispatch => {
    dispatch({ type: VOTE_LOADING });
    api
      .post(`/deal-vote/disable`, { deal_id })
      .then(({ data }) => {
        dispatch({ type: DEAL_LOADED, payload: data });
      })
      .catch(({ response }) => {
        dispatch({ type: DEAL_LOADED_FAILED });
        Notifier({
          type: 'error',
          title: response && response.data && response.data.message,
          message: 'Please try again',
        });
      });
  };
}

export function sendVote(vote, deal_id) {
  return dispatch => {
    dispatch({ type: VOTE_LOADING });
    api
      .post(`/deal-vote/vote`, { vote, deal_id })
      .then(({ data }) => {
        dispatch({ type: DEAL_LOADED, payload: data });
      })
      .catch(({ response }) => {
        dispatch({ type: DEAL_LOADED_FAILED });
        Notifier({
          type: 'error',
          title: response && response.data && response.data.message,
          message: 'Please try again',
        });
      });
  };
}

export function loadDealInvestments(id) {
  return dispatch =>
    api
      .get(`/rounds?filter[deal_id]=${id}`)
      .then(({ data }) => {
        dispatch({ type: INVESTMENTS_LOADED, payload: data.results });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
}

export function deleteDealRound(round_id) {
  return dispatch => {
    api
      .delete(`/rounds/${round_id}`)
      .then(_ => {
        Notifier({
          type: 'open',
          title: 'Round deleted.',
          message: 'Round was successfuly deleted.',
        });
        dispatch({ type: DELETE_ROUND, payload: round_id });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
  };
}

export function saveDealRound(values) {
  return dispatch =>
    api
      .post(`/rounds`, values)
      .then(data => {
        Notifier({
          type: 'success',
          title: 'Round added.',
          message: 'Round was added successfully!',
        });
        dispatch({ type: INVESTMENTS_CREATED, payload: data.data });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
}

export function updateDealRound(values) {
  return dispatch =>
    api
      .put(`/rounds/${values.round_id}`, values)
      .then(data => {
        Notifier({
          type: 'success',
          title: 'Round updated.',
          message: 'Round was updated successfully!',
        });
        dispatch({ type: ROUND_UPDATED, payload: data.data });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
}

export function loadDealInvestors(id) {
  return dispatch =>
    api
      .get(`/investors/${id}/list`)
      .then(({ data }) => {
        dispatch({ type: INVESTORS_LOADED, payload: data });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
}

export function deleteInvestor(investor_id, deal_id) {
  return dispatch => {
    api
      .post(`/investors/unlink`, { investor_id, deal_id })
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Investor unlinked.',
          message: 'Investor was successfuly unlinked from this deal.',
        });
        dispatch({ type: INVESTORS_LOADED, payload: data });
      })
      .catch(error =>
        dispatch(
          Notifier({
            type: 'error',
            title: errorMessage(error),
            message: 'Please try again',
          }),
        ),
      );
  };
}

export function exitInvestor(investor_id, deal_id, exitDate) {
  return dispatch => {
    api
      .post(`/investors/${investor_id}/exit-deal`, { deal_id, exit_date: exitDate })
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Investor Exited.',
          message: 'Investor was successfuly exited from this deal.',
        });
        dispatch({ type: INVESTORS_LOADED, payload: data });
      })
      .catch(error =>
        dispatch(
          Notifier({
            type: 'error',
            title: errorMessage(error),
            message: 'Please try again',
          }),
        ),
      );
  };
}

export function createInvestor(investor, deal_id) {
  return dispatch =>
    api
      .post(`/investors/link`, { ...investor, deal_id })
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Investor linked.',
          message: 'Investor was linked successfully!',
        });
        dispatch({ type: INVESTORS_LOADED, payload: data });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
}

export function updateInvestor(investor, deal_id) {
  return dispatch =>
    api
      .post(`/investors/deal/update`, { ...investor, deal_id })
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Investor updated.',
          message: 'Investor was updated successfully!',
        });
        dispatch({ type: INVESTOR_UPDATED, payload: data });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: error ? errorMessage(error) : 'Error updating investor information.',
          message: 'Please try again',
        }),
      );
}

export function loadInvestors(deal_id) {
  return dispatch =>
    api
      .get(`/investors/${deal_id}`)
      .then(({ data }) => {
        dispatch({ type: ALL_INVESTORS_LOADED, payload: data.results });
      })
      .catch(error =>
        Notifier({
          type: 'error',
          title: errorMessage(error),
          message: 'Please try again',
        }),
      );
}

export function dealRateLoaded(deal_id) {
  return dispatch => {
    api
      .get(`/return-rate?filter[deal_id]=${deal_id}`)
      .then(({ data }) => {
        dispatch({ type: DEAL_RATES_LOADED, payload: data.results });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Something went wrong',
          message: 'Could not load rates',
        });
      });
  };
}

export function dealCashOutLoaded(deal_id) {
  return dispatch => {
    apiV2
      .get(`/v2/deals/${deal_id}/cashout`)
      .then(({ data }) => {
        dispatch({ type: DEAL_CASHOUT_LOADED, payload: data });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Something went wrong',
          message: 'Could not load cash out',
        });
      });
  };
}

export function dealRateCreated(rate) {
  return dispatch => {
    api
      .post(`/return-rate`, rate)
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Rate created successfully',
        });
        dispatch({ type: DEAL_RATE_CREATED, payload: data });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Something went wrong',
          message: 'Could not create rate',
        });
      });
  };
}

export function dealCashOutCreated(rate) {
  return dispatch => {
    apiV2
      .post(`/v2/deals/cashout`, rate)
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Cash out successfully', //ahmed fix
        });
        dispatch({ type: DEAL_CASH_OUT_CREATED, payload: data });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Something went wrong',
          message: 'Could not cash out', //ahmed fix
        });
      });
  };
}

export function dealRateUpdated(rate) {
  return dispatch => {
    api
      .put(`/return-rate/${rate.return_rate_id}`, rate)
      .then(({ data }) => {
        Notifier({
          type: 'info',
          title: 'Rate updated successfully',
        });
        dispatch({ type: DEAL_RATE_UPDATED, payload: data });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Something went wrong',
          message: 'Could not update rate',
        });
      });
  };
}

export function dealCashOutUpdated(cash) {
  return dispatch => {
    apiV2
      .put(`/v2/deals/cashout/${cash.id}`, cash)
      .then(({ data }) => {
        Notifier({
          type: 'info',
          title: 'Cash out updated successfully',
        });
        dispatch({ type: DEAL_CASH_OUT_UPDATED, payload: data });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Something went wrong',
          message: 'Could not update cash out',
        });
      });
  };
}

export function dealRateDeleted(return_rate_id) {
  return dispatch => {
    api
      .delete(`/return-rate/${return_rate_id}`)
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Rate deleted successfully',
        });
        dispatch({ type: DEAL_RATE_DELETED, payload: return_rate_id });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Something went wrong',
          message: 'Could not delete rate',
        });
      });
  };
}

export function dealCashOutDeleted(id) {
  return dispatch => {
    apiV2
      .delete(`/v2/deals/cashout/${id}`)
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Cash out deleted successfully',
        });
        dispatch({ type: DEAL_CASH_OUT_DELETED, payload: id });
      })
      .catch(error => {
        Notifier({
          type: 'error',
          title: 'Something went wrong',
          message: 'Could not delete cash out',
        });
      });
  };
}

export function loadPortfolioStatistics(ids = []) {
  return dispatch => {
    dispatch({ type: PORTFOLIO_DASH_STATS_LOADING });
    api
      .post(`/portfolios/dashboard`, {
        portfolio_ids: typeof ids === 'number' ? [ids] : ids.split(','),
      })
      .then(({ data }) => {
        dispatch({ type: PORTFOLIO_DASH_STATS_LOADED, payload: data });
      })
      .catch(error => {
        dispatch({ type: PORTFOLIO_DASH_STATS_LOADED, payload: {} });
        Notifier({
          type: 'error',
          title: 'Could not get portfolio dashboard stats.',
          message: 'Please try again later or contact support.',
        });
      });
  };
}

export function loadPipelineStatistics(ids, filters = {}) {
  return dispatch => {
    dispatch({ type: PORTFOLIO_DASH_STATS_LOADING });
    api
      .post(`/pipelines/chart-stats`, {
        pipeline_ids: ids ? ids.split(',') : [],
        ...normalizeFilters(filters),
      })
      .then(({ data }) => {
        dispatch({ type: PORTFOLIO_DASH_STATS_LOADED, payload: data });
      })
      .catch(error => {
        dispatch({ type: PORTFOLIO_DASH_STATS_LOADED, payload: {} });
        Notifier({
          type: 'error',
          title: 'Could not get pipeline dashboard stats.',
          message: 'Please try again later or contact support.',
        });
      });
  };
}

export function loadPortfolioReportDetails(ids = []) {
  return dispatch => {
    dispatch({ type: PORTFOLIO_DASH_STATS_EXPORT_LOADING });
    api
      .post(`/portfolios/export`, {
        portfolio_ids: typeof ids === 'string' ? ids.split(',') : [ids],
      })
      .then(({ data }) => {
        dispatch({ type: PORTFOLIO_DASH_STATS_EXPORT_LOADED, payload: data });
      })
      .catch(error => {
        dispatch({ type: PORTFOLIO_DASH_STATS_EXPORT_LOADED, payload: {} });
        Notifier({
          type: 'error',
          title: 'Could not get portfolio dashboard report details.',
          message: 'Please try again later or contact support.',
        });
      });
  };
}

export function linkAngelInvestors(deal_id, investor_ids) {
  return dispatch => {
    api
      .post(`/angel-investor/deal/${deal_id}/link-pipeline`, { investor_ids })
      .then(({ data }) => {
        dispatch({
          type: ANGEL_INVESTORS_LOADED,
          payload: data,
        });
        Notifier({
          type: 'success',
          title: 'Selected investors linked',
        });
      })
      .catch(error => {});
  };
}

export function loadAngelInvestors(deal_id) {
  return dispatch => {
    api.get(`angel-investor/deal/${deal_id}/list`).then(({ data }) => {
      dispatch({
        type: ANGEL_INVESTORS_LOADED,
        payload: data,
      });
    });
  };
}

export function shareAngelNote({ share, deal_id, note_id }) {
  return dispatch => {
    api
      .post(`/angel-investor/note/${note_id}/share`, { share: share })
      .then(({ data }) => {
        dispatch({ type: NOTE_DEAL_UPDATED, payload: data });
        Notifier({
          type: 'info',
          title: share ? 'Note shared with investors' : 'Note unshared',
          message: '',
        });
      })
      .then(error => {});
  };
}

export function createAngelInvestor(investor, deal_id) {
  return dispatch => {
    api
      .post(`/angel-investor/deal/${deal_id}/link-portfolio`, investor)
      .then(({ data }) => {
        dispatch({
          type: ANGEL_INVESTORS_LOADED,
          payload: data,
        });
        Notifier({
          type: 'success',
          title: 'Investors linked',
        });
      })
      .catch(error => {});
  };
}

export function unlinkAngelInvestor(investor_id, deal_id) {
  return dispatch => {
    api
      .post(`/angel-investor/deal/${deal_id}/unlink-portfolio`, { investor_id, deal_id })
      .then(({ data }) => {
        Notifier({
          type: 'success',
          title: 'Investor unlinked.',
          message: 'Investor was successfuly unlinked from this deal.',
        });
        dispatch({ type: INVESTORS_LOADED, payload: data });
      })
      .catch(error =>
        dispatch(
          Notifier({
            type: 'error',
            title: errorMessage(error),
            message: 'Please try again',
          }),
        ),
      );
  };
}

export function loadAllAngelInvestors() {
  return dispatch => {
    api
      .get('/angel-investor?sort=status,last_login')
      .then(({ data }) => {
        dispatch({
          type: ALL_INVESTORS_LOADED,
          payload: data.results,
        });
      })
      .catch(error => {});
  };
}

export function toggleAngelInvestorVote(vote_enabled, deal_id) {
  return dispatch => {
    api
      .post(`/deals/${deal_id}/toggle-angel-inv-vote`, { vote_enabled })
      .then(({ data }) => {
        dispatch({
          type: DEAL_UPDATED,
          payload: { isAngelInvVoteEnabled: vote_enabled },
        });
      })
      .catch(() => {
        dispatch({
          type: DEAL_UPDATED,
          payload: { isAngelInvVoteEnabled: !vote_enabled },
        });
      });
  };
}

function getFileNameFromHeaders(headers) {
  let contentDisposition = headers['content-disposition'];
  let match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
  return match[1];
}

export function exportDealVoteResult(dealId) {
  return dispatch => {
    api
      .get(`/angel-investor/deal/${dealId}/export`, {
        responseType: 'arraybuffer',
      })
      .then(response => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const a = document.createElement('a');
        const fileURL = window.URL.createObjectURL(file);
        a.href = fileURL;
        a.download = getFileNameFromHeaders(response.headers);
        a.click();
        Notifier({
          type: 'success',
          title: 'Success',
          message: `Vote results exported successfully `,
        });
      })
      .catch(error => {
        try {
          dispatchAlert(dispatch, {
            message: `${error.data.message}`,
            type: 'danger',
          });
        } catch (err) {
          Notifier({
            type: 'error',
            title: 'Error',
            message: `There was an error exporting Vote results `,
          });
        }
      });
  };
}
export function exportPipelineDeals(pipelineId, pipelineName) {
  return dispatch => {
    Notifier({
      type: 'info',
      title: 'Info',
      message: `Exporting "${pipelineName}" deals...`,
    });
    api
      .get(`/pipelines/${pipelineId}/export`, {
        responseType: 'arraybuffer',
      })
      .then(response => {
        const file = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const a = document.createElement('a');
        const fileURL = window.URL.createObjectURL(file);
        a.href = fileURL;
        a.download = `${pipelineName}-Deals-${new Date().toISOString().split('T')[0]}.xls`;
        a.click();
        Notifier({
          type: 'success',
          title: 'Success',
          message: `The pipeline "${pipelineName}" have been successfully exported.`,
        });
      })
      .catch(error => {
        try {
          dispatchAlert(dispatch, {
            message: `${error.data.message}`,
            type: 'danger',
          });
        } catch (err) {
          Notifier({
            type: 'error',
            title: 'Error',
            message: `There was an error exporting pipeline "${pipelineName}"`,
          });
        }
      });
  };
}
