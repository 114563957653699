import React from 'react';
import { Button, Icon, Modal } from 'antd';
import './ConfirmUpgradeModal.scss';

function ConfirmUpgradeModal({ onCancel, onConfirm, isVisible, isConfirmUpgradeLoading }) {
  return (
    <Modal className='confirm-upgrade-modal' visible={isVisible} footer={null} closable={false}>
      <div className='confirm-upgrade-modal__content'>Note* we are going to use the credit card you subscribed with</div>
      <div className='confirm-upgrade-modal__actions'>
        <Button onClick={onCancel} type='danger'>
          Cancel
        </Button>
        <Button onClick={onConfirm} type='primary' disabled={isConfirmUpgradeLoading}>
          {isConfirmUpgradeLoading ? <Icon type='loading' /> : 'Confirm'}
        </Button>
      </div>
    </Modal>
  );
}

export default ConfirmUpgradeModal;
