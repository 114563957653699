import React, { useCallback, useEffect, useState } from 'react';
import { Card, message } from 'antd';
import { connect } from 'react-redux';

import { at } from 'Utils/objectUtils';
import { VC_PACKS } from 'Enums/payment';

import { loadPaymentHistory, loadPacks, callbackSubscription, upgradePackage } from 'ReduxActions/billingActions.js';

import UpgradeModal from '../UpgradeModal';
import HocBilling from '../HocBilling';
import BillingTable from '../BillingTable/BillingTable.js';
import PlanCard from '../PlanCard';
import ConfirmChange from '../Confirm/ConfirmChange';

import { PermissibleRender } from 'SharedComponent/permissions';
import { PAYMENT_STATUS } from 'Enums/permissions';

import { appCategories, appActions, appEvents } from 'GoogleAnalytics/eventArgs';
import { gaEvent } from 'GoogleAnalytics/eventFunctions';

import './Billing.scss';

import { products } from './listCompany';

// import api from '../../../../actions/api.js';
import { getBillingPackages } from './BillingApiCall';
import { set } from 'lodash';
import PackageUpgradeModal from '../PackageUpgradeModal';
import ConfirmUpgradeModal from '../ConfirmUpgradeModal/ConfirmUpgradeModal.js';
import { update } from '../../../../utils/arrayUtils.js';

const actions = { loadPacks, loadPaymentHistory };

function Billing(props) {
  const [product, setProduct] = useState(null);
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showUpgradeModal, setUpgradeModal] = useState(false);
  const [packages, setPackages] = useState();
  const [canUpgrade, setCanUpgrade] = useState(false);
  const [isUpgradeSuccessfull, setIsUpgradeSuccessfull] = useState(false);
  const [isConfirmUpgradeModalVisiable, setIsConfirmUpgradeModalVisiable] = useState(false);
  const [productInfo, setProductInfo] = useState({});
  const [isConfirmUpgradeLoading, setIsConfirmUpgradeLoading] = useState(false);

  useEffect(() => {
    reloadDataList();
    getBillingPackages(result => {
      setPackages(result.data);
      setCanUpgrade(props.userProductId === result.data.find(item => item.name.toLowerCase().includes('silver')).id);
    });
  }, []);

  const reloadDataList = () => {
    props.loadPaymentHistory(1, 10);
  };

  const onSubscribe = product => {
    if (props.canSubscribe) subscribePaddle(product);
    if (canUpgrade) {
      setProductInfo(product);
      setIsConfirmUpgradeModalVisiable(true);
    }
  };

  const upgradePaddle = product => {
    setProduct(product);
    setConfirmModal(true);
  };

  const subscribePaddle = product => {
    props.paddle.Checkout.open({
      customer: {
        email: at(props.me, 'email'),
      },
      settings: {
        allowLogout: false,
      },
      items: [
        {
          priceId: product,
          quantity: at(props.me, 'total_users') || 1,
        },
      ],
      customData: { company_id: at(props.me, 'company.company_id'), user_id: at(props.me, 'id') },
    });
  };

  const cancelSubscription = () => {
    const cancelUrl = at(props.me, 'subscription.cancel_url');
    if (cancelUrl) {
      props.paddle.Checkout.open({
        override: cancelUrl,
        // successCallback: (data, err) => location.reload()
      });
    }
  };

  const updatePayment = (product, setIsConfirmUpgradeLoading) => {
    // const paddle_transaction_id = at(props.me, 'subscription.paddle_transaction_id');
    if (product) {
      upgradePackage(product, setIsUpgradeSuccessfull, setIsConfirmUpgradeLoading);
    }
  };

  const parsRejected = (list = []) => {
    return list.map(element => ({
      ...element,
      status: 'REJECTED',
      sale_gross: element.amount,
      hideActions: true,
    }));
  };

  const onEnterCoupon = () => {
    const productID = products[at(props.me, 'company.company_id')];
    if (productID) {
      const selectedProduct = packagesData.find(p => p.id === productID);

      props.paddle.Checkout.open({
        product: productID,
        email: at(props.me, 'email'),
        allowQuantity: false,
        quantity: at(props.me, 'total_users') || 1,
        disableLogout: true,
        data: { company_id: at(props.me, 'company.company_id'), user_id: at(props.me, 'id') },
      });
    } else message.error('Your subscription payment failed (Product not found).');
  };

  const renderCard = useCallback(() => {
    if (!packages) return null;
    const myPlan = packages
      .map(pack => {
        const price = pack.prices.find(price => price.id === props.userPlanId);
        return price;
      })
      .filter(item => item !== undefined && item !== null)[0];
    const packLogoColor = myPlan ? JSON.parse(myPlan.custom_data.colors) : ['#1871D1', '#0095FF'];
    const tagColor = packLogoColor[0];
    const sliverProductId = packages.find(item => item.name.toLowerCase() === 'silver').id;
    return (
      <PlanCard
        packName={(myPlan && myPlan.name) || 'trial'}
        packLogo={myPlan && packLogoColor}
        tagColor={myPlan && tagColor}
        frequency={myPlan && myPlan.description}
        createdAt={at(props.me, 'company.created_at')}
        canUpgrade={canUpgrade}
        canSubscribe={props.canSubscribe}
        onUpgrade={() => setUpgradeModal(true)}
        onSubscribe={() => setUpgradeModal(true)}
        onCancel={cancelSubscription}
        expiryDate={at(props.me, 'subscription.trial_expire_at')}
        planDate={at(props.me, 'subscription.next_bill_date')}
        onEnterCoupon={onEnterCoupon}
      />
    );
  }, [packages, canUpgrade]);

  const items = [...props.paid, ...parsRejected(props.rejected)];

  return (
    <div className='Billing'>
      <div className='mt-3'>
        <PermissibleRender userPermissions={[]} requiredPermissions={[[PAYMENT_STATUS.STATUS_PAST_DUE]]}>
          <div className='mb-3'>
            <Card bordered={true}>
              <div className='suspended-title-card text-center'>Your subscription has been paused.</div>
            </Card>
          </div>
        </PermissibleRender>
        <div className='row'>
          <div className='col-xs-12 col-sm-12 col-md-4'>
            <div className='card border-light mb-3'>
              <div className='display-flex-between card-header'>
                <div>Your Plan</div>
              </div>
              <div className='card-body'>
                <PermissibleRender userPermissions={[]} requiredPermissions={[[PAYMENT_STATUS.STATUS_DELETED, PAYMENT_STATUS.STATUS_PAUSED]]} renderOtherwise={renderCard()}>
                  <PlanCard
                    packName=''
                    packLogo={['#1871D1', '#0095FF']}
                    tagColor={['', '']}
                    frequency=''
                    createdAt={at(props.me, 'company.created_at')}
                    canUpgrade={false}
                    canSubscribe={true}
                    onUpgrade={() => {}}
                    onSubscribe={() => {
                      setUpgradeModal(true);
                      gaEvent(appCategories.settings_billing, appActions('SUBSCRIBE_PLAN', appEvents.clicked)['button']);
                    }}
                    onCancel={() => {}}
                    expiryDate={at(props.me, 'subscription.trial_expire_at')}
                    planDate={at(props.me, 'subscription.next_bill_date')}
                    onEnterCoupon={onEnterCoupon}
                  />
                </PermissibleRender>
              </div>
            </div>
          </div>
          <div className='col-xs-12 col-sm-12 col-md-8'>
            <div className='card border-light mb-3'>
              <div className='card-header'>Payment History</div>
              <div className='card-body'>
                <BillingTable isLoading={props.paymentLoading} items={items} onShow={e => {}} page={1} sizePerPage={10} totalDataSize={items.length} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpgradeModal
        isVisible={showUpgradeModal}
        onClose={() => setUpgradeModal(false)}
        allPacks={canUpgrade ? packages.filter(item => item.name.toLowerCase().includes('gold')) : packages || []}
        onSubscribe={onSubscribe}
        total_users={props.total_users}
      />
      <ConfirmChange
        isVisible={showConfirmModal}
        product={product}
        onClose={() => {
          setConfirmModal(false);
          setProduct(null);
        }}
      />

      <PackageUpgradeModal isVisible={isUpgradeSuccessfull} />
      <ConfirmUpgradeModal
        isConfirmUpgradeLoading={isConfirmUpgradeLoading}
        isVisible={isConfirmUpgradeModalVisiable}
        onCancel={() => {
          setIsConfirmUpgradeModalVisiable(false);
        }}
        onConfirm={() => {
          updatePayment(productInfo, setIsConfirmUpgradeLoading);
        }}
      />
      {/* <HocBilling /> */}
    </div>
  );
}

function mapStateToProps(state) {
  const planId = at(state, 'auth.user.subscription.paddle_price_id');
  const isTrial = at(state, 'auth.user.subscription.is_trial');
  const packs = state.settings.billing.packs;
  const myPlan = planId ? VC_PACKS[planId] : VC_PACKS.TRIAL;
  const planOrder = myPlan ? myPlan.order : 1;
  const availablePacks = packs.filter(it => planOrder < it.order);
  const productId = at(state, 'auth.user.subscription.paddle_product_id');
  return {
    me: state.auth.user,
    paid: state.settings.billing.paid,
    rejected: state.settings.billing.rejected,
    paymentLoading: state.settings.billing.loading,
    packs,
    userPlanId: planId,
    myPlan,
    availablePacks,
    canSubscribe: !Boolean(planId),
    canUpgrade: Boolean(planId) && Boolean(availablePacks.length),
    userProductId: productId,
    paddle: state.settings.billing.paddle,
    total_users: at(state, 'auth.user.total_users') || 0,
  };
}

export default connect(mapStateToProps, actions)(Billing);
