export const ANIM_SIDEMENU_HIGHTLIGHT = 'anim:hightlight:sidemenu';

export function hightlightSidemenu(item) {
  console.log('hightlightSidemenu', item);
  return dispatch => {
    dispatch({
      type: ANIM_SIDEMENU_HIGHTLIGHT,
      payload: item,
    });
  };
}
