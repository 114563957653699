import React from 'react';
import { Modal } from '../Modal/modal.component';
import { ModalIdEnum } from '../../../core/enums/entity-ids/modal-id.enum';
import './PipelineOpportunitiesModal.scss';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { closeModal } from '../Modal/state/modal.actions';
import { hightlightSidemenu } from '../../../../actions/animActions';
import { browserHistory } from 'react-router';

interface PipelineOpportunitiesModalProps {
  onClose(): void;
}

function PipelineOpportunitiesModal(props: PipelineOpportunitiesModalProps) {
  const dispatch = useDispatch();

  return (
    <Modal
      modalId={ModalIdEnum.pipelineOpportunitiesFeautureAnnouncement}
      title='Pipeline Opportunities'
      width='40rem'
      hasTitle={true}
      onCancel={props.onClose}
      extraClasses='pipeline-opportunities-modal'
    >
      <div>
        <div className='pipeline-opportunities-modal-header'></div>
        <div className='pipeline-opportunities-modal-content'>
          <div className='pipeline-opportunities-modal-content__badge'>Exciting News!</div>
          <div className='pipeline-opportunities-modal-content__title'>
            <h3>Exclusive Opportunity for Gold Members</h3>
            <p>Unlock exclusive access to our two new pipeline opportunities designed just for you</p>
          </div>
          <div className='pipeline-opportunities-modal-content__liquidation-pipeline'>
            <h3>
              <div className='pipeline-opportunities-modal-content__liquidation-pipeline__checkmark' />
              <span>Liquidation Pipeline</span>
            </h3>
            <p>Optimize your assets with our streamlined liquidation process. Benefit from faster transactions and maximize your returns.</p>
          </div>
          <div className='pipeline-opportunities-modal-content__liquidation-pipeline'>
            <h3>
              <div className='pipeline-opportunities-modal-content__liquidation-pipeline__checkmark' />
              <span>Fundraising Pipeline</span>
            </h3>
            <p>Seamlessly raise funds with our innovative tools and expert support. Achieve your financial goals with ease.</p>
          </div>
        </div>
        <div className='pipeline-opportunities-modal-footer'>
          <Button
            type='default'
            size='large'
            onClick={() => {
              props.onClose();
              dispatch(hightlightSidemenu(true));
              dispatch(closeModal());
            }}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            style={{ backgroundColor: '#3070CC', borderColor: '#3070CC' }}
            size='large'
            onClick={() => {
              props.onClose();
              dispatch(closeModal());
              dispatch(hightlightSidemenu(true));
              browserHistory.push('/fundraising');
            }}
          >
            Explore
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PipelineOpportunitiesModal;
