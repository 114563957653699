import React, { Fragment } from 'react';
import { Router, Route, IndexRoute, browserHistory, Redirect, IndexRedirect } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import { at } from 'Utils/objectUtils';
import { config } from './config';
import NotFount from './NotFound';
import { hasMetrix } from 'Utils/arrayUtils';
import api from 'ReduxActions/api';
import { get } from '../v2.0/core/services/helpers/base-http';
import { PermissionTypeEnum } from '../v2.0/core/enums/permission/permission-type.enum';

import SuspenseComponent from './SuspenseComponent';

import ContactView from '../components/contacts/ContactView/ContactView';
import Company from '../components/settings/Company';
import EmailTemplatesContext from '../components/settings/emails/EmailTemplatesContext/EmailTemplatesContext';
import Pipelines from '../components/settings/pipelines/Pipelines/Pipelines';
import Users from '../components/settings/users/Users/Users';
import ProfileContext from '../components/profile/ProfileContext/ProfileContext';
import FormContext from '../components/settings/form/FormContext/FormContext';
import FormView from '../components/settings/form/view/FormView/FormView';
import CustomFields from '../components/settings/customFields/CustomFields/CustomFields';
import Billing from '../components/settings/billing/Billing';
import PackageUpgradeModal from '../components/settings/billing/PackageUpgradeModal/PackageUpgradeModal';
import ExpiredBilling from '../components/settings/billing/ExpiredBilling';
import SuspendedAccount from '../components/settings/billing/SuspendedAccount';
import FundsContext from '../components/settings/funds/FundsContext';

import NotificationsContext from '../components/notifications/NotificationsContext/NotificationsContext';
import ReportsContext from '../components/reports/ReportsContext/ReportsContext';

import TemplateEditorContext from '../components/reports/TemplateEditorContext/TemplateEditorContext';

import RegisterModal from '../components/auth/Register/RegisterModal/RegisterModal';
import NotFound from '../components/NotFound/NotFound';
import Slack from '../components/integrations/Slack';
import Integrations from '../v2.0/modules/settings/integrations/integrations.component';
import { Permissions } from '../v2.0/modules/settings/permissions/permissions.component';
import NotAuthorized from '../v2.0/shared/components/NotAuthorized/not-authorized';
import PipelineOpportunitiesUpgradeModal from '../v2.0/shared/components/PipelineOpportunitiesUpgradeModal/PipelineOpportunitiesUpgradeModal';
import PipelineOpportunitiesInterestedModal from '../v2.0/shared/components/PipelineOpportunitiesInterestedModal/PipelineOpportunitiesInterestedModal';
import PipelineOpportunitiesModal from '../v2.0/shared/components/PipelineOpportunitiesModal/PipelineOpportunitiesModal';
import Cookies from 'universal-cookie';
import { openModal } from '../v2.0/shared/components/Modal/state/modal.actions';
import { ModalIdEnum } from '../v2.0/core/enums/entity-ids/modal-id.enum';
// Dashboard Lazy
const NavBar = React.lazy(() => import(/* webpackChunkName: "navbar" */ '../components/app/NavBar'));
// const DashboardContext = React.lazy(() => import(/* webpackChunkName: "d-home" */ '../components/dashboard/DashboardContext'));
const Dashboard = React.lazy(() => import(/* webpackChunkName: "d-home" */ '../v2.0/modules/dashboard/dashboard.component'));
const PipelineContext = React.lazy(() => import(/* webpackChunkName: "d-pipeline" */ '../components/pipeline/PipelineContext'));
// const Pipeline = React.lazy(() => import(/* webpackChunkName: "d-pipeline" */ '../v2.0/modules/pipeline/pipeline.component'));
const PortfolioContext = React.lazy(() => import(/* webpackChunkName: "d-portfolio" */ '../components/portfolio/PortfolioContext'));
const ContactsContext = React.lazy(() => import(/* webpackChunkName: "d-contacts" */ '../components/contacts/ContactsContext'));
const ReportList = React.lazy(() => import(/* webpackChunkName: "d-report-list" */ '../components/reports/ReportList'));
const TemplateList = React.lazy(() => import(/* webpackChunkName: "d-report-template" */ '../components/reports/TemplateList'));
const Tasks = React.lazy(() => import(/* webpackChunkName: "tasks" */ '../v2.0/modules/tasks/tasks.component'));
const TasksTable = React.lazy(() => import(/* webpackChunkName: "tasks" */ '../v2.0/modules/tasks/tasks-table/tasks-table.component'));
const TasksCalender = React.lazy(() => import(/* webpackChunkName: "tasks" */ '../v2.0/modules/tasks/tasks-calender/tasks-calender.component'));
const ReportContainer = React.lazy(() => import(/* webpackChunkName: "d-report-editor" */ '../components/reports/ReportContainer'));
// Deal Lazy
const DealContext = React.lazy(() => import(/* webpackChunkName: "deal" */ '../components/deal/DealContext'));
const ActivityContext = React.lazy(() => import(/* webpackChunkName: "deal-activity" */ '../components/deal/ActivityContext'));
const MetricsContext = React.lazy(() => import(/* webpackChunkName: "deal-metrics" */ '../components/deal/metrics/MetricsContext'));
const MetricGroupContext = React.lazy(() => import(/* webpackChunkName: "deal-metrics-group" */ '../components/deal/metrics/MetricGroupContext'));
const EmailsContext = React.lazy(() => import(/* webpackChunkName: "deal-emails" */ '../components/deal/EmailsContext'));
const NotesContext = React.lazy(() => import(/* webpackChunkName: "deal-notes" */ '../components/deal/NotesContext'));
const TasksContext = React.lazy(() => import(/* webpackChunkName: "deal-tasks" */ '../components/deal/TasksContext'));
const FilesContext = React.lazy(() => import(/* webpackChunkName: "deal-files" */ '../components/deal/FilesContext'));

// Settings Lazy
const SettingsContext = React.lazy(() => import(/* webpackChunkName: "settings" */ '../components/settings/SettingsContext'));

// Auth lazy
const Register = React.lazy(() => import(/* webpackChunkName: "register" */ '../components/auth/Register'));
const ResetPassword = React.lazy(() => import(/* webpackChunkName: "reset-password" */ '../components/auth/ResetPassword'));
const VerfiyOtp = React.lazy(() => import(/* webpackChunkName: "reset-password" */ '../components/auth/VerifyOtp'));
const SignIn = React.lazy(() => import(/* webpackChunkName: "sign-in" */ '../components/auth/SignIn'));

// Lazy routes without protection
const ReportViewContext = React.lazy(() => import(/* webpackChunkName: "report-view" */ '../components/reports/ReportViewContext'));
const FormViewContext = React.lazy(() => import(/* webpackChunkName: "form-view" */ '../components/settings/form/FormViewContext'));
const MetricOnlineFormContext = React.lazy(() => import(/* webpackChunkName: "online-metrics" */ '../components/onlineformMetric/MetricOnlineFormContext'));

export default function (store) {
  const history = syncHistoryWithStore(browserHistory, store);
  const token = localStorage.getItem('token');

  const AUTHENTICATED = (_nextState, replace, callback) => {
    api
      .get('/me')
      .then(({ data }) => {
        get('/v2/me')
          .then(data => store.dispatch({ type: 'AUTH:SIGNING:V2', payload: data }))
          .then(_ => {
            store.dispatch({ type: 'auth:signedIn', payload: data });
            callback();
          });
      })
      .catch(_error => {
        if (localStorage.getItem('token')) localStorage.removeItem('token');
        replace({ pathname: '/signin' });
        callback();
      });
  };

  const NOT_AUTHENTICATED = (_nextState, replace, callback) => {
    if (token) {
      api
        .get('/me')
        .then(({ data }) => {
          get('/v2/me')
            .then(data => store.dispatch({ type: 'AUTH:SIGNING:V2', payload: data }))
            .then(_ => {
              replace({ pathname: '/' });
              store.dispatch({ type: 'auth:signedIn', payload: data });
              callback();
            });
        })
        .catch(error => {
          callback();
        });
    } else callback();
  };

  const myAttributes = () => {
    return at(store.getState(), 'auth.user.user_attributes');
  };

  const pagePermissions = () => {
    return at(store.getState(), 'v2.me.user.pages');
  };

  const actionPermissions = () => {
    return at(store.getState(), 'v2.me.user.actions');
  };

  const conditionGuard = ({ authorize = [], redirect }) => {
    return (_nextState, replace, callback) => {
      const me = myAttributes();
      if (hasMetrix(authorize, me)) {
        if (me.includes('OWNER')) {
          replace({ pathname: '/billing' });
          callback();
        } else {
          replace({ pathname: '/suspended' });
          callback();
        }
      } else {
        replace({ pathname: redirect });
        callback();
      }
    };
  };

  const permissionGuard = ({ authorize = [], redirect }) => {
    return (_nextState, replace, callback) => {
      const me = myAttributes();
      if (hasMetrix(authorize, me) || me.includes('WEALTH_MANAGEMENT')) callback();
      else {
        replace({ pathname: redirect });
        callback();
      }
    };
  };

  const guardV2 = permission => {
    return (_nextState, replace, callback) => {
      const pages = pagePermissions();
      if ((pages || []).includes(permission)) {
        callback();
      } else {
        replace({ pathname: '/unauthorized' });
        callback();
      }
    };
  };

  const guardByActionV2 = permission => {
    return (_nextState, replace, callback) => {
      const actions = actionPermissions();
      if ((actions || []).includes(permission)) {
        callback();
      } else {
        replace({ pathname: '/unauthorized' });
        callback();
      }
    };
  };

  const redirectPage = () => {
    /*see with @ahmed */
    return (_nextState, replace, callback) => {
      const pages = pagePermissions();
      const allowedPages = PermissionTypeEnum.sideBarPages.find(page => (pages || []).includes(page));
      if (!!allowedPages) {
        replace({ pathname: PermissionTypeEnum.convertToRouteSideBar.getValue(allowedPages) });
        callback();
      } else {
        replace({ pathname: '/unauthorized' });
        callback();
      }
    };
  };

  const redirectPageSetting = () => {
    return (_nextState, replace, callback) => {
      const pages = pagePermissions();
      const allowedPages = PermissionTypeEnum.settingPages.find(page => (pages || []).includes(page));
      if (!!allowedPages) {
        replace({ pathname: `/settings` + PermissionTypeEnum.convertToRouteSetting.getValue(allowedPages) });
        callback();
      } else {
        replace({ pathname: `/settings` + '/unauthorized' });
        callback();
      }
    };
  };

  const isUserGold = () => {
    return (_nextState, replace, callback) => {
      const goldPackageId = 'pro_01hqgqqzfjbg2c9ymksdt50byt';
      const userSubscriptionId = at(store.getState(), 'auth.user.subscription.paddle_product_id');
      if (goldPackageId === userSubscriptionId) {
        const pages = pagePermissions();
        if ((pages || []).includes(PermissionTypeEnum.PipelinePage)) {
          callback();
        } else {
          replace({ pathname: '/unauthorized' });
          callback();
        }
      } else {
        replace({ pathname: '/dashboard' });
        callback();
        store.dispatch(openModal({ modalId: ModalIdEnum.pipelineOpportunitiesUpgrade }));
      }
    };
  };

  return (
    <Fragment>
      <Router history={history}>
        <Route onEnter={AUTHENTICATED}>
          <Route onEnter={permissionGuard(config.notExpired)}>
            <Route path='/' onEnter={permissionGuard(config.index)} component={props => <SuspenseComponent component={<NavBar {...props} />} />}>
              <IndexRoute onEnter={redirectPage()} />
              <Route path='dashboard' onEnter={guardV2(PermissionTypeEnum.DashboardPage)} component={() => <SuspenseComponent component={<Dashboard />} />} />
              <Route path='deals'>
                {/* <IndexRedirect from='' to='/pipeline' /> */}
                <Route path=':id' component={props => <SuspenseComponent component={<DealContext {...props} />} />}>
                  {/* <IndexRedirect to='notes' /> */}
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PortfolioDealHistoryPage)}
                    path='portfolio/history'
                    component={props => <SuspenseComponent component={<ActivityContext portfolioDeal={true} {...props} />} />}
                  />
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PipelineDealHistoryPage)}
                    path='pipeline/history'
                    component={props => <SuspenseComponent component={<ActivityContext portfolioDeal={false} {...props} />} />}
                  />
                  <Route onEnter={guardV2(PermissionTypeEnum.PipelineDealMetricPage)} path='pipeline/metrics'>
                    <IndexRoute component={props => <SuspenseComponent component={<MetricsContext portfolioDeal={false} {...props} />} />} />
                    <Route path=':group' component={props => <SuspenseComponent component={<MetricGroupContext portfolioDeal={false} {...props} />} />} />
                  </Route>
                  <Route onEnter={guardV2(PermissionTypeEnum.PortfolioDealMetricPage)} path='portfolio/metrics'>
                    <IndexRoute component={props => <SuspenseComponent component={<MetricsContext portfolioDeal={true} {...props} />} />} />
                    <Route path=':group' component={props => <SuspenseComponent component={<MetricGroupContext portfolioDeal={true} {...props} />} />} />
                  </Route>
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PortfolioDealEmailPage)}
                    path='portfolio/emails'
                    component={props => <SuspenseComponent component={<EmailsContext portfolioDeal={true} {...props} />} />}
                  />
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PipelineDealEmailPage)}
                    path='pipeline/emails'
                    component={props => <SuspenseComponent component={<EmailsContext portfolioDeal={false} {...props} />} />}
                  />
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PortfolioDealNotePage)}
                    path='portfolio/notes'
                    component={props => <SuspenseComponent component={<NotesContext portfolioDeal={true} {...props} />} />}
                  />
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PipelineDealNotePage)}
                    path='pipeline/notes'
                    component={props => <SuspenseComponent component={<NotesContext portfolioDeal={false} {...props} />} />}
                  />
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PortfolioDealTaskPage)}
                    path='portfolio/tasks'
                    component={props => <SuspenseComponent component={<TasksContext portfolioDeal={true} {...props} />} />}
                  />
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PipelineDealTaskPage)}
                    path='pipeline/tasks'
                    component={props => <SuspenseComponent component={<TasksContext portfolioDeal={false} {...props} />} />}
                  />
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PortfolioDealFilePage)}
                    path='portfolio/files'
                    component={props => <SuspenseComponent component={<FilesContext portfolioDeal={true} {...props} />} />}
                  />
                  <Route
                    onEnter={guardV2(PermissionTypeEnum.PipelineDealFilePage)}
                    path='pipeline/files'
                    component={props => <SuspenseComponent component={<FilesContext portfolioDeal={false} {...props} />} />}
                  />
                  <Route path='unauthorized' component={props => <SuspenseComponent component={<NotAuthorized {...props} />} />} />
                </Route>
              </Route>

              <Route onEnter={guardV2(PermissionTypeEnum.PipelinePage)} path='pipeline' component={() => <SuspenseComponent component={<PipelineContext />} />} />
              {/* pipelines opportunity routes */}
              <Route path='liquidation' onEnter={isUserGold()} component={() => <SuspenseComponent component={<PipelineContext />} />} />
              <Route path='fundraising' onEnter={isUserGold()} component={() => <SuspenseComponent component={<PipelineContext />} />} />

              <Route onEnter={guardV2(PermissionTypeEnum.PortfolioPage)} path='portfolio' component={() => <SuspenseComponent component={<PortfolioContext />} />} />

              <Route onEnter={guardV2(PermissionTypeEnum.ReportPage)} path='reports' component={ReportsContext}>
                <IndexRoute component={() => <SuspenseComponent component={<ReportList />} />} />
                <Route path='templates' component={() => <SuspenseComponent component={<TemplateList />} />} />
              </Route>

              <Route path='contacts' onEnter={guardV2(PermissionTypeEnum.ContactPage)}>
                <IndexRoute component={() => <SuspenseComponent component={<ContactsContext />} />} />
                <Route path=':id' component={ContactView} />
              </Route>

              <Route path='notFound' component={NotFound} />

              <Route path='tasks' onEnter={guardV2(PermissionTypeEnum.TaskPage)} component={props => <SuspenseComponent component={<Tasks {...props} />} />}>
                <IndexRedirect to='list' />
                <Route path='list' component={props => <SuspenseComponent component={<TasksTable {...props} />} />} />
                <Route path='calender' component={props => <SuspenseComponent component={<TasksCalender {...props} />} />} />
                <Redirect from='*' to='list' />
              </Route>

              <Route path='notifications' onEnter={permissionGuard(config.notifications)} component={NotificationsContext} />

              <Route path='settings' onEnter={guardV2(PermissionTypeEnum.SettingsPage)} component={props => <SuspenseComponent component={<SettingsContext {...props} />} />}>
                <IndexRoute onEnter={redirectPageSetting()} />
                <Route path='company' onEnter={guardV2(PermissionTypeEnum.CompanySettingPage)} component={Company} />
                <Route path='users' onEnter={guardV2(PermissionTypeEnum.UserSettingPage)} component={Users} />
                <Route path='pipelines' onEnter={guardV2(PermissionTypeEnum.PipelinePage)} component={() => <Pipelines isPortfolio={false} />} />
                <Route path='portfolio' onEnter={guardV2(PermissionTypeEnum.PortfolioPage)} component={() => <Pipelines isPortfolio={true} />} />
                <Route path='funds' onEnter={guardV2(PermissionTypeEnum.FundSettingPage)} component={FundsContext} />
                <Route path='email-templates' component={EmailTemplatesContext} />
                <Route path='integrations' onEnter={guardV2(PermissionTypeEnum.IntegrationSettingPage)} component={Integrations} />
                <Route path='forms' onEnter={guardV2(PermissionTypeEnum.FormSettingPage)} component={FormContext} />
                <Route path='custom-fields' onEnter={guardV2(PermissionTypeEnum.CustomFieldSettingPage)} component={CustomFields} />
                <Route path='billing' onEnter={permissionGuard(config.settings_billing_owner)} component={Billing} />
                <Route path='permissions' onEnter={permissionGuard(config.settings_permissions)} component={Permissions} />
                <Route path='unauthorized' component={props => <SuspenseComponent component={<NotAuthorized {...props} />} />} />
              </Route>

              <Route path='form-editor' onEnter={guardByActionV2(PermissionTypeEnum.CreateUpdateForm)}>
                <IndexRoute component={FormView} />
                <Route path='edit/:id' component={FormView} />
              </Route>

              <Route path='profile' component={ProfileContext} />

              <Route path='slack-integration' component={Slack} />
              <Route path='unauthorized' component={() => <NotFount />} />
            </Route>

            {/* without guard */}
            <Route path='report-editor' onEnter={guardByActionV2(PermissionTypeEnum.CreateUpdateReport)} component={TemplateEditorContext}>
              <IndexRoute to='create' />
              <Route path='create(/:id)' component={() => <SuspenseComponent component={<ReportContainer />} />} />
              <Route path='edit/:id' component={() => <SuspenseComponent component={<ReportContainer />} />} />
            </Route>

            <Route path='template-editor' onEnter={guardByActionV2(PermissionTypeEnum.CreateUpdateReport)} component={TemplateEditorContext}>
              <Route path='edit/:id' component={() => <SuspenseComponent component={<ReportContainer />} />} />
            </Route>

            <Route path='/registering' onEnter={permissionGuard(config.registering)} component={RegisterModal} />
          </Route>

          {/* Expired routes */}
          <Route path='/notice' onEnter={conditionGuard(config.expired)} />
          <Route path='/' onEnter={permissionGuard(config.expired)} component={props => <SuspenseComponent component={<NavBar {...props} />} />}>
            <Route path='/billing' onEnter={permissionGuard(config.settings_billing_owner)} component={ExpiredBilling} />
          </Route>
          <Route path='/' onEnter={permissionGuard(config.expired)}>
            <Route path='/suspended' onEnter={permissionGuard(config.settings_billing_employees)} component={SuspendedAccount} />
          </Route>
        </Route>

        <Route path='/signin' onEnter={NOT_AUTHENTICATED} component={() => <SuspenseComponent component={<SignIn />} />} />
        <Route path='/register' onEnter={NOT_AUTHENTICATED} component={() => <SuspenseComponent component={<Register />} />} />
        <Route path='/resetPassword' onEnter={NOT_AUTHENTICATED} component={() => <SuspenseComponent component={<ResetPassword />} />} />
        <Route path='/forgetPasswordVerifyOtp' onEnter={NOT_AUTHENTICATED} component={() => <SuspenseComponent component={<VerfiyOtp />} />} />
        <Route path='/registerVerifyOtp' onEnter={NOT_AUTHENTICATED} component={props => <SuspenseComponent component={<VerfiyOtp {...props} />} />} />

        {/* routes without protection */}
        <Route path='report/:id' component={props => <SuspenseComponent component={<ReportViewContext {...props} />} />} />
        <Route path='template/:id' component={props => <SuspenseComponent component={<ReportViewContext {...props} />} />} />
        <Route path='forms/:id' component={props => <SuspenseComponent component={<FormViewContext {...props} />} />} />
        <Route path='forms/:id/edit' component={props => <SuspenseComponent component={<FormViewContext {...props} />} />} />
        <Route path='metric-online-form/:id' component={props => <SuspenseComponent component={<MetricOnlineFormContext {...props} />} />} />

        <Redirect from='*' to='/' />
      </Router>
      <PipelineOpportunitiesUpgradeModal onClose={() => {}} />
      <PipelineOpportunitiesInterestedModal onClose={() => {}} />
      <PackageUpgradeModal />
      <PipelineOpportunitiesModal
        onClose={() => {
          const _cookies = new Cookies(null, { path: '/' });
          _cookies.set('pipelineOpportunityAnnouncement', true);
        }}
      />
    </Fragment>
  );
}
