import { Modal, Popconfirm, Icon, Steps, Button, message } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, change, getFormValues } from 'redux-form';

import { getLabelValue } from 'SharedComponent/permissions';
import SubmitButton from 'SharedComponent/SubmitButton';
import { loadPipelines, loadPipelineStage, createDeal, updateDeal, deleteDeal } from 'ReduxActions/pipelineActions';
import { utilActions } from 'ReduxActions/utilActions';
import { mapWithMissingParticipate, mapToCurrentAccountData, mapToConvertibleNoteData, mapToEquityData } from '../../../enums/roundFields';
import DealForm from './DealForm';
import RoundForm from './RoundForm';

const actions = {
  loadPipelines,
  loadPipelineStage,
  createDeal,
  updateDeal,
  deleteDeal,
  getLabelValue,
  ...utilActions,
};

import './DealModal.scss';

function DealModal(props) {
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const steps = [
    {
      title: 'Deal details',
      content: (
        <form id={props.form} onSubmit={props.handleSubmit(e => (props.multiSteps ? next() : submit(e)))}>
          <DealForm
            label_value={props.getLabelValue(props.isPortfolio)}
            isPortfolio={props.isPortfolio}
            // ---custom fields ---
            customFields={props.customFields}
            contactCustomFields={props.contactCustomFields}
            // ---pipelines ---
            extraPipelines={props.extraPipelines}
            pipelines={props.pipelines}
            onLoadPipelineStage={id => onLoadPipelineStage(id)}
            // --- stages ---
            stages={props.stages}
            // --- extra props ---
            extra={props.extra}
            domains={props.domains}
            users={props.users}
            // --- Clearbit api ---
            clearbitCompanies={props.clearbitCompanies}
            clearbitLoading={props.clearbitLoading}
            searchClearbit={props.searchClearbit}
            initializeClearbit={oc => initializeClearbit(oc)}
            // --- formValues ---
            formValues={props.formValues}
          />
        </form>
      ),
    },
    {
      title: 'Round details',
      content: (
        <form id='deal-form-second-step' onSubmit={props.handleSubmit(e => submit(e))}>
          <RoundForm formValues={props.formValues} />
        </form>
      ),
    },
  ];

  const confirm = () => {
    props.deleteDeal(props.initialValues);
  };

  const closeModal = elem => {
    props.reset();
    props.onDismiss();
    setCurrentStep(0);
  };

  function submit(values) {
    const dealObject = values;
    if (!props.multiSteps) {
      delete dealObject.round;
      if (props.initialValues && props.initialValues.hasOwnProperty('name')) {
        props.updateDeal(dealObject, closeModal, setLoading);
      } else {
        props.createDeal(dealObject, closeModal, setLoading);
      }
    } else if (values.round.investment_type === 'Equity' && values.round.is_participate) {
      if (props.initialValues && props.initialValues.hasOwnProperty('name')) {
        props.updateDeal({ ...dealObject, round: mapToEquityData(dealObject.round) }, closeModal, setLoading);
      } else {
        props.createDeal({ ...dealObject, round: mapToEquityData(dealObject.round) }, closeModal, setLoading);
      }
    } else if (values.round.investment_type === 'Convertible note' && values.round.is_participate) {
      if (props.initialValues && props.initialValues.hasOwnProperty('name')) {
        props.updateDeal({ ...dealObject, round: mapToConvertibleNoteData(dealObject.round) }, closeModal, setLoading);
      } else {
        props.createDeal({ ...dealObject, round: mapToConvertibleNoteData(dealObject.round) }, closeModal, setLoading);
      }
    } else if (values.round.investment_type === 'Loan' && values.round.is_participate) {
      if (props.initialValues && props.initialValues.hasOwnProperty('name')) {
        props.updateDeal({ ...dealObject, round: mapToCurrentAccountData(dealObject.round) }, closeModal, setLoading);
      } else {
        props.createDeal({ ...dealObject, round: mapToCurrentAccountData(dealObject.round) }, closeModal, setLoading);
      }
    } else if (!values.round.is_participate) {
      if (props.initialValues && props.initialValues.hasOwnProperty('name')) {
        props.updateDeal({ ...dealObject, round: mapWithMissingParticipate(dealObject.round) }, closeModal, setLoading);
      } else {
        props.createDeal({ ...dealObject, round: mapWithMissingParticipate(dealObject.round) }, closeModal, setLoading);
      }
    }
    // closeModal();
  }

  const initializeClearbit = object => {
    props.dispatch(change(props.form, 'website', object.website));
    props.dispatch(change(props.form, 'image', object.image));
  };

  const onLoadPipelineStage = event => {
    props.loadPipelineStage(event);
    props.dispatch(change(props.form, 'stage_id', null));
  };

  function title() {
    if (props.multiSteps) {
      return steps[currentStep].title;
    }
    return props.initialValues ? 'Edit Deal' : 'New Deal';
  }

  function oneStepFooter() {
    return (
      <div className='display-flex-between'>
        <div className='flex flex-1 pl-3'>
          {props.initialValues && (
            <Popconfirm
              title='Are you sure you want to delete the deal ?'
              placement='topLeft'
              onConfirm={confirm}
              okText='Delete'
              okType='danger'
              cancelText='Cancel'
              icon={<Icon type='delete' style={{ color: 'red' }} />}
            >
              <SubmitButton
                label='Delete'
                icon='icon-trash'
                style={{
                  width: '150px',
                  color: '#ff8181',
                  backgroundColor: '#fff',
                  border: '1px solid #ff8181',
                }}
              />
            </Popconfirm>
          )}
        </div>
        <div className='flex flex-1'>
          <div className='m-1'>
            <SubmitButton
              label='Cancel'
              icon='icon-close'
              style={{
                width: '150px',
                color: '#53627c',
                backgroundColor: '#fff',
                border: '1px solid #e1e4e8',
              }}
              onClick={closeModal}
            />
          </div>
          <div className='m-1'>
            <SubmitButton
              label={loading ? 'Loading...' : 'SAVE'}
              icon={loading ? 'icon-reload' : 'icon-check'}
              type='submit'
              disabled={props.submitting || props.pristine || loading}
              style={{ width: '150px' }}
              className='submit-btn-p'
              formId={props.form}
            />
          </div>
        </div>
      </div>
    );
  }

  function twoStepFooter() {
    return (
      <div className='display-flex-between'>
        <SubmitButton
          label='Cancel'
          icon='icon-close'
          style={{
            width: '150px',
            color: '#53627c',
            backgroundColor: '#fff',
            border: '1px solid #e1e4e8',
          }}
          onClick={closeModal}
        />
        <div className='display-flex-end'>
          {currentStep > 0 && (
            <SubmitButton
              label='Previous'
              icon='icon-arrow-left'
              style={{
                width: '150px',
                color: '#53627c',
                backgroundColor: '#fff',
                border: '1px solid #e1e4e8',
              }}
              onClick={prev}
            />
          )}
          {currentStep < steps.length - 1 && (
            <SubmitButton label='Next' icon='icon-arrow-right' style={{ width: '150px' }} className='submit-btn-p' type='submit' formId={props.form} />
          )}
          {currentStep === steps.length - 1 && (
            <div className='ml-1'>
              <SubmitButton
                label={loading ? 'Loading...' : 'SAVE'}
                icon={loading ? 'icon-reload' : 'icon-check'}
                type='submit'
                disabled={props.submitting || props.pristine || loading}
                style={{ width: '150px' }}
                className='submit-btn-p'
                formId='deal-form-second-step'
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <div className='DealModal'>
      <Modal
        visible={props.showDetails}
        title={title()}
        onCancel={closeModal}
        footer={props.multiSteps ? twoStepFooter() : oneStepFooter()}
        wrapClassName='DealModal'
        bodyStyle={{ padding: 0 }}
        width={750}
      >
        <div className='deal-form-container'>{steps[currentStep].content}</div>
      </Modal>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  let filterPipelines = [];
  let extraPipelines = [];
  if (state.pipeline.pipelines) {
    filterPipelines = state.pipeline.pipelines.filter(pipeline => !pipeline.is_system_pipeline);
  }
  if (state.pipeline.extra) {
    extraPipelines = state.pipeline.extra.filter(pipeline => !pipeline.is_system_pipeline);
  }
  return {
    form: ownProps.formName || 'add-new-deal',
    pipelines: filterPipelines,
    extraPipelines: extraPipelines,
    stages: state.pipeline.stages.sort((a, b) => a.stage_order - b.stage_order),
    users: state.settings.users.all,
    currencies: state.utils.currencies,
    domains: state.utils.domains,
    countries: state.utils.countries,
    // initialValues: state.pipeline.editing,
    clearbitCompanies: state.utils.companies,
    clearbitLoading: state.utils.loading,
    formValues: getFormValues(ownProps.formName || 'add-new-deal')(state),
  };
}

export default compose(connect(mapStateToProps, actions), reduxForm({ enableReinitialize: true }))(DealModal);
