import findIndex from 'lodash/findIndex';

export function replace(array, predicate, newItem) {
  const itemIndex = findIndex(array, predicate);

  if (itemIndex > -1) {
    array.splice(itemIndex, 1, newItem);
  }

  return [...array];
}

export function swap(array, indexA, indexB) {
  const newArray = [...array];
  const temp = newArray[indexA];
  newArray[indexA] = newArray[indexB];
  newArray[indexB] = temp;

  return newArray;
}

export function shift(arr) {
  return arr.map((_, i, a) => a[(i + a.length - 1) % a.length]);
}

export function update(array, predicate, newValues) {
  const itemIndex = findIndex(array, predicate);

  if (itemIndex > -1) {
    array.splice(itemIndex, 1, { ...array[itemIndex], ...newValues });
  }

  return [...array];
}

export function hasSome(arr1, arr2) {
  return arr1.some(r => arr2.includes(r));
}

export function hasMetrix(arrays, arr) {
  let val = true;
  for (let i in arrays) {
    if (!hasSome(arrays[i], arr)) {
      val = false;
      break;
    }
  }
  return val;
}

export function arrayToEntities(list, key) {
  return list.reduce((obj, item) => ((obj[item[key]] = item), obj), {});
}
