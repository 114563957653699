import React, { useEffect } from 'react';
import Script from 'react-load-script';
import { useDispatch, useSelector } from 'react-redux';

import { ModalIdEnum } from '../../../core/enums/entity-ids/modal-id.enum';
import { IntegrationsEnum } from '../../../core/enums/settings/integrations-enums/integrations-enum';
import { FormIdEnum } from '../../../core/enums/entity-ids/form-id.enum';
import { PermissionTypeEnum } from '../../../core/enums/permission/permission-type.enum';
import { integrationAuth, calendarAuth } from '../../../core/helpers/integrations-oauth2';

import { openModal, closeModal } from '../../../shared/components/Modal/state/modal.actions';
import { resetReduxForm } from '../../../shared/components/Form/state/form.actions';
import { getSubmitted } from '../../../shared/components/Modal/state/modal.selectors';
import { dummyAction } from '../../../shared/utils/shared-functions';
import { Permissible } from '../../../shared/components';

import PermissibleRender from '../../../../components/shared/permissions/PermissibleRender';
import { OWNER } from '../../../../enums/permissions';

import { connectPipedrive, connectSlack, connectSlackFail, connectCalendar, connectZoom, connectZoomFail } from './state/integration.actions';
import { pipedriveMigrated, slackState as slackToken, isCalendarAuthorized, isZoomAuthorized, isImportingExcel } from '../../../store/me/me.selectors';
import { actions } from '../../../../actions/authActions';

import { IntegrationCard } from './Integration-card/integration-card.component';
import { PipedriveModal } from './dialogs/pipedrive/pipedrive-modal.component';
import { CalendarModal } from './dialogs/calendar/calendar-modal.component';
import { ExcelModal } from './dialogs/excel/excel.component';
import { Mixpanel } from 'App/app';

import './integrations.component.scss';
// tslint:disable-next-line:no-any
var gapi;

// tslint:disable-next-line:no-default-export
export default function Integrations() {
  const dispatch = useDispatch();
  const isSubmitting = useSelector(getSubmitted);
  const isPipedriveMigrated = useSelector(pipedriveMigrated);
  const disableUploadExcel = useSelector(isImportingExcel);
  const slackState = useSelector(slackToken);
  const isSlackConnected = useSelector(state => state.v2.me.user.isSlackAuthorized);
  const isZoomConnected = useSelector(isZoomAuthorized);
  const isCalendarConnected = useSelector(isCalendarAuthorized);

  const migrationInProgress = isPipedriveMigrated === 2;
  const migrated = isPipedriveMigrated === 1;

  const slackAuthUrl = process.env.SLACK_AUTHORIZATION;
  const slackClientId = process.env.SLACK_CLIENT_ID;
  const slackRedirectUri = process.env.SLACK_REDIRECT_URI;
  const slackUrl = `${slackAuthUrl}&state=${slackState}&client_id=${slackClientId}&redirect_uri=${slackRedirectUri}`;
  const zoomUrl = `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.ZOOM_CLIENT_ID}&redirect_uri=${process.env.ZOOM_REDIRECT_URI}`;

  useEffect(() => {
    dispatch(actions.checkToken());
  }, []);

  useEffect(() => {
    console.log('isSlackConnected', isSlackConnected);
  }, [isSlackConnected]);

  const closeDialog = () => {
    dispatch(closeModal());
  };

  const closePipedriveModal = () => {
    dispatch(resetReduxForm(FormIdEnum.pipedriveForm));
    closeDialog();
  };

  const onSlackSuccess = (slackCode, userState) => dispatch(connectSlack({ code: slackCode, state: userState }));
  const onSlackFail = message => dispatch(connectSlackFail({ message: message, status: null, name: 'error' }));

  const showPipedriveModal = () => {
    dispatch(openModal({ modalId: ModalIdEnum.pipedriveIntegration }));
    Mixpanel.track('Clicked on Pipedrive integration');
  };
  const showCalendarModal = () => {
    dispatch(openModal({ modalId: ModalIdEnum.googleCalendarIntegration }));
    Mixpanel.track('Clicked on Google Calendar integration');
  };
  const showExcelModal = () => {
    dispatch(openModal({ modalId: ModalIdEnum.excelIntegration }));
    Mixpanel.track('Clicked on Excel integration');
  };

  const pipedriveSubmit = values => dispatch(connectPipedrive({ token: values }));
  const startSlackIntegration = () => {
    integrationAuth({ integrationUrl: slackUrl, onSuccess: onSlackSuccess, onFailure: onSlackFail });
    Mixpanel.track('Clicked on Slack integration');
  };
  const onZoomSuccess = zoomCode => dispatch(connectZoom({ code: zoomCode }));
  const onZoomFail = message => dispatch(connectZoomFail({ message: message, status: null, name: 'error' }));
  const startZoomIntegration = () => {
    integrationAuth({ integrationUrl: zoomUrl, onSuccess: onZoomSuccess, onFailure: onZoomFail });
    Mixpanel.track('Clicked on Zoom integration');
  };
  const onCalendarSuccess = code => dispatch(connectCalendar({ code }));
  const onCalendarClick = () => calendarAuth({ gapi: gapi, onCalendarSuccess: onCalendarSuccess });

  return (
    <div className='Integrations'>
      <div className='integration-Section-name'>3rd Party Integrations</div>
      <div className='integration-row'>
        <PermissibleRender userPermissions={[]} requiredPermissions={[[OWNER]]}>
          <IntegrationCard
            description={IntegrationsEnum.getDescription(IntegrationsEnum.Slack)}
            name={IntegrationsEnum.getName(IntegrationsEnum.Slack)}
            imagePath={IntegrationsEnum.getImage(IntegrationsEnum.Slack)}
            imageBackgroundColor={IntegrationsEnum.getImageColor(IntegrationsEnum.Slack)}
            onClick={startSlackIntegration}
            disabled={isSlackConnected}
            isThirdParty={true}
          />
        </PermissibleRender>
        <Permissible can={PermissionTypeEnum.IntegrateZoom}>
          <IntegrationCard
            description={IntegrationsEnum.getDescription(IntegrationsEnum.Zoom)}
            name={IntegrationsEnum.getName(IntegrationsEnum.Zoom)}
            imagePath={IntegrationsEnum.getImage(IntegrationsEnum.Zoom)}
            imageBackgroundColor={IntegrationsEnum.getImageColor(IntegrationsEnum.Zoom)}
            disabled={isZoomConnected}
            onClick={startZoomIntegration}
            isThirdParty={true}
          />
        </Permissible>
        <Permissible can={PermissionTypeEnum.GoogleCalendarIntegration}>
          <IntegrationCard
            description={IntegrationsEnum.getDescription(IntegrationsEnum.GoogleCalendar)}
            name={IntegrationsEnum.getName(IntegrationsEnum.GoogleCalendar)}
            imagePath={IntegrationsEnum.getImage(IntegrationsEnum.GoogleCalendar)}
            imageBackgroundColor={IntegrationsEnum.getImageColor(IntegrationsEnum.GoogleCalendar)}
            disabled={isCalendarConnected}
            onClick={showCalendarModal}
            isThirdParty={true}
          />
        </Permissible>
      </div>
      <div className='integration-Section-name'>Data import</div>
      <div className='integration-row'>
        <Permissible can={PermissionTypeEnum.MigratePipeDriveData}>
          <IntegrationCard
            description={IntegrationsEnum.getDescription(IntegrationsEnum.Pipedrive)}
            name={IntegrationsEnum.getName(IntegrationsEnum.Pipedrive)}
            imagePath={IntegrationsEnum.getImage(IntegrationsEnum.Pipedrive)}
            imageBackgroundColor={IntegrationsEnum.getImageColor(IntegrationsEnum.Pipedrive)}
            onClick={showPipedriveModal}
            disabled={migrated}
            loading={migrationInProgress}
          />
        </Permissible>
        <IntegrationCard
          description={IntegrationsEnum.getDescription(IntegrationsEnum.Excel)}
          name={IntegrationsEnum.getName(IntegrationsEnum.Excel)}
          imagePath={IntegrationsEnum.getImage(IntegrationsEnum.Excel)}
          imageBackgroundColor={IntegrationsEnum.getImageColor(IntegrationsEnum.Excel)}
          onClick={showExcelModal}
          disabled={disableUploadExcel}
        />
      </div>
      <PipedriveModal onSubmit={pipedriveSubmit} onClose={closePipedriveModal} submitting={isSubmitting} />
      <CalendarModal onCalendarClick={onCalendarClick} />
      <ExcelModal onClose={closeDialog} submitting={isSubmitting} />
      <Script url={'https://apis.google.com/js/client:platform.js'} onCreate={dummyAction} onLoad={dummyAction} onError={dummyAction} />
    </div>
  );
}
