import { Notifier } from 'SharedComponent/Notifier';
import api from './api';
import debounceAction from './debounceCreator';
import { SEARCHING_GROUP_METRIC } from './metricsActions';

export const UPDATE_EDITOR_PROPS = 'reports:template:UPDATE_EDITOR_PROPS';
export const ADDED_PAGE_TO_REPORT = 'reports:template:ADDED_PAGE_TO_REPORT';
export const REMOVE_PAGE_FROM_REPORT = 'reports:template:REMOVE_PAGE_FROM_REPORT';
export const CHANGE_REPORT_PAGE = 'reports:template:CHANGE_REPORT_PAGE';
export const UNMOUNT_REDUCER = 'reports:report:unmount';
export const LOADING_REPORTS = 'reports:loading';
export const LOADED_TEMPLATES = 'reports:templates:loaded';
export const TEMPLATE_DELETED = 'reports:templates:deleted';
export const LOADING_TEMPLATES = 'reports:templates:loading';
export const LOADED_REPORTS = 'reports:loaded';
export const LOADED_REPORT = 'reports:loaded:by:id';
export const REPORT_DELETED = 'reports:deleted:successfully';
export const TEMPLATE_EDITOR_FIELD_ADDED = 'template:editor:field:added';
export const ITEM_SELECTED = 'template:editor:field:item:selected';
export const ITEM_DELETED = 'template:editor:field:item:deteled';
export const RESET_REPORT = 'template:editor:report:reset';

export const LOADING_CONTENT = 'reports:templates:content:loading';
export const SWITCH_TO_PAGE = 'reports:templates:switching:page';

export const INITIALIZE_APP = 'template:editor:initialize_app';
export const ADD_TO_SCENE = 'template:editor:add_to_scene';
export const DROP_ELEMENT = 'template:editor:drop_element';
export const MAKE_ELEMENT_ACTIVE = 'template:editor:make_element_active';
export const CHANGE_PROPERTIES = 'template:editor:change_properties';
export const UPDATE_ELEMENT = 'template:editor:update_item';
export const ADDED_COVER_PAEGE = 'template:editor:ADDED_COVER_PAEGE';
export const SHARED_COMPONENT_ADDED = 'template:editor:SHARED_COMPONENT_ADDED';
export const SHARED_COMPONENT_REMOVED = 'template:editor:SHARED_COMPONENT_REMOVED';
export const UPDATE_TOOLS = 'template:editor:UPDATE_TOOLS';

export const REPORT_ITEM_LOADING = 'template:editor:REPORT_ITEM_LOADING';
export const PENTUGRAM_ITEMS_LOADED = 'template:editor:PENTUGRAM_ITEMS_LOADED';
export const PERIOD_UPDATED = 'template:editor:PERIOD_UPDATED';
export const REPORT_TEMPLATE_LOADED = 'template:editor:REPORT_TEMPLATE_LOADED';
export const UPDATE_GLOBAL_COLOR = 'template:editor:UPDATE_GLOBAL_COLOR';
export const COPY_ELEMENT = 'template:editor:COPY_ELEMENT';
export const ZOOM_IN_OUT = 'template:editor:ZOOM_IN_OUT';
export const MOVE_REPORT_PAGE = 'report:editor:move:report:page';
export const SWITCH_PAGE_ORIENTATION = 'report:editor:page:orientation:switch';
export const ADD_TO_CONTENT_TABLE = 'report:editor:page:content_table:add';
export const REMOVE_FROM_CONTENT_TABLE = 'report:editor:page:content_table:remove';
export const SEND_TO_BACK = 'report:editor:page:element:send:back';
export const SEND_TO_FRONT = 'report:editor:page:element:send:front';
export const DUPLICATE_PAGE = 'report:editor:page:duplicate_page';
export const ADD_REPORT_PAGE_COMMENT = 'report:editor:page:comment:add';
export const REMOVE_REPORT_PAGE_COMMENT = 'report:editor:page:comment:remove';
export const UPDATE_REPORT_PAGE_COMMENT = 'report:editor:page:comment:update';
export const SELECT_ITEMS = 'report:editor:selection:selected:items';
export const PASTE_SELECTED_ITEMS = 'report:editor:selection:paste:items';
export const CLEAR_SELECTED_ITEMS = 'report:editor:selection:clear:items';

export function unmountReducer() {
  return dispatch => dispatch({ type: UNMOUNT_REDUCER });
}

export function sharedComponent(item, shared) {
  return dispatch => {
    dispatch({
      type: UPDATE_ELEMENT,
      payload: item,
      id: item.id,
    });
  };
}

export function updateTools(field, value) {
  return dispatch => dispatch({ type: UPDATE_TOOLS, payload: { field, value } });
}

export function onAddPage() {
  return dispatch => dispatch({ type: ADDED_PAGE_TO_REPORT });
}

export function onRemovePage(page) {
  return dispatch => dispatch({ type: REMOVE_PAGE_FROM_REPORT, payload: page });
}

export function onMovePage(oldIndex, newIndex) {
  return dispatch => dispatch({ type: MOVE_REPORT_PAGE, oldIndex, newIndex });
}

export function onChangePage(page) {
  return dispatch => dispatch({ type: CHANGE_REPORT_PAGE, payload: page });
}

export function updateEditorProps(type, mode, id, key) {
  return dispatch => dispatch({ type: UPDATE_EDITOR_PROPS, payload: { type, mode, id, key } });
}

export function loadReports(current_page, sort = '-created_at') {
  return dispatch => {
    dispatch({ type: LOADING_REPORTS });
    api
      .get(`/reports?page=${current_page}&sort=${sort}`)
      .then(({ data }) => {
        dispatch({
          type: LOADED_REPORTS,
          payload: data,
        });
      })
      .catch();
  };
}

export function loadReportByKey(key) {
  return dispatch => {
    dispatch({ type: LOADING_CONTENT, payload: true });
    api
      .get(`/reports/key/${key}`)
      .then(({ data }) => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        dispatch({
          type: LOADED_REPORT,
          payload: data,
        });
      })
      .catch(({ response }) => {
        let msg = response && response.data;
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'error',
          title: msg && msg.name,
          message: msg && msg.message,
        });
      });
  };
}

export function loadReport(id) {
  return dispatch => {
    dispatch({ type: LOADING_CONTENT, payload: true });
    api
      .get(`/reports/${id}`)
      .then(({ data }) => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        dispatch({
          type: LOADED_REPORT,
          payload: data,
        });
      })
      .catch(({ response }) => {
        let msg = response && response.data;
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'error',
          title: msg && msg.name,
          message: msg && msg.message,
        });
      });
  };
}

export function deleteReport(report) {
  return dispatch => {
    api
      .delete(`/reports/${report.report_id}`)
      .then(response => {
        Notifier({
          type: 'open',
          title: 'Report deleted successfully',
          message: report.title,
        });
        dispatch({ type: REPORT_DELETED, payload: report.report_id });
      })
      .catch(({ response }) => {
        let msg = response && response.data;
        Notifier({
          type: 'error',
          title: msg && msg.name,
          message: msg && msg.message,
        });
      });
  };
}

export function shareReport(share) {
  return dispatch => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const formData = new FormData();
    if (share.send_pdf) formData.append('file', share.file);
    formData.append('html', share.html);
    formData.append('subject', share.subject);
    formData.append('id', share.id);
    share.email.forEach((element, idx) => {
      formData.append('email[]', element);
    });
    api
      .post('/reports/share', formData, config)
      .then(e => {
        Notifier({
          type: 'success',
          title: 'Report shared successfully',
          message: '',
        });
      })
      .catch();
  };
}

export function copyElement(item) {
  return dispatch => {
    dispatch({ type: COPY_ELEMENT, payload: item });
  };
}

//-------TEMPLATES------

export function saveTemplate(template) {
  return dispatch => {
    dispatch({ type: LOADING_CONTENT, payload: true });
    api
      .post('/report-template', template)
      .then(({ data }) => {
        dispatch({ type: LOADING_CONTENT, payload: false });
      })
      .catch();
  };
}

export function loadTemplates(per_page) {
  let url = per_page ? `/report-template?per-page=${per_page}&sort=-created_at` : '/report-template?sort=-created_at';
  return dispatch => {
    dispatch({ type: LOADING_TEMPLATES });
    api
      .get(url)
      .then(({ data }) => {
        dispatch({
          type: LOADED_TEMPLATES,
          payload: data.results,
        });
      })
      .catch();
  };
}

export function loadTemplate(id) {
  return dispatch => {
    dispatch({ type: LOADING_CONTENT, payload: true });
    api
      .get(`/report-template/${id}`)
      .then(({ data }) => {
        dispatch({
          type: REPORT_TEMPLATE_LOADED,
          payload: data,
        });
        dispatch({ type: LOADING_CONTENT, payload: false });
      })
      .catch(({ response }) => {
        let msg = response && response.data;
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'error',
          title: msg && msg.name,
          message: msg && msg.message,
        });
      });
  };
}

export function deleteTemplate(template) {
  return dispatch => {
    api
      .delete(`/report-template/${template.template_id}`)
      .then(res => {
        dispatch({ type: TEMPLATE_DELETED, payload: template.template_id });
        Notifier({
          type: 'open',
          title: 'Template has been deleted',
          message: template.title,
        });
      })
      .catch();
  };
}

export function updateTemplate(template) {
  return dispatch => {
    api
      .put(`/report-template/${template.template_id}`, template)
      .then(({ data }) => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'open',
          title: 'Template updated successfully',
          message: 'Check your templates',
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'error',
          title: 'Unfortunately, your template has been lost',
          message: '',
        });
      });
  };
}

//-------REPORTS--------

export function onSaving(payload) {
  return dispatch => dispatch({ type: LOADING_CONTENT, payload });
}

export function switchToPage(page) {
  return dispatch => dispatch({ type: SWITCH_TO_PAGE, payload: page });
}

export function saveReport(report) {
  return dispatch => {
    if (report.save_as_template) {
      api
        .post('/report-template', report)
        .then(({ data }) => {
          Notifier({
            type: 'success',
            title: 'Template saved successfully',
            message: 'Check your templates',
          });
        })
        .catch(error => {
          Notifier({
            type: 'error',
            title: 'Unfortunately, your template has been lost',
            message: '',
          });
        });
    }
    api
      .post('/reports', report)
      .then(({ data }) => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'success',
          title: 'Report saved successfully',
          message: report.title,
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'error',
          title: 'Unfortunately, your report has not been saved',
          message: 'Please try again',
        });
      });
  };
}

export function updateSimpleReport(object) {
  return dispatch => {
    const report = { ...object, number_of_page: object.pages };
    api
      .put(`/reports/${report.report_id}`, report)
      .then(({ data }) => {})
      .catch(error => {});
  };
}

export function updateReport(object) {
  return dispatch => {
    const report = { ...object, number_of_page: object.pages };
    if (report.save_as_template) {
      api
        .post('/report-template', report)
        .then(({ data }) => {
          Notifier({
            type: 'open',
            title: 'Template saved successfully',
            message: 'Check your templates',
          });
        })
        .catch(error => {
          Notifier({
            type: 'error',
            title: 'Unfortunately, your template has been lost',
            message: '',
          });
        });
    }
    api
      .put(`/reports/${report.report_id}`, report)
      .then(({ data }) => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'info',
          title: 'Report updated successfully',
          message: report.title,
        });
      })
      .catch(error => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        Notifier({
          type: 'error',
          title: 'Unfortunately, your report has not been updated',
          message: 'Please try again',
        });
      });
  };
}

export const initializeApp = settings => ({
  type: INITIALIZE_APP,
  payload: settings,
});

export function loadSettings() {
  return dispatch => {
    const data = {};
    dispatch(initializeApp(data));
  };
}

export function addToScene(id, object) {
  return dispatch => {
    dispatch({
      type: ADD_TO_SCENE,
      payload: { id, object },
    });
  };
}

export function makeElementActive(elementId) {
  return dispatch => {
    dispatch({
      type: MAKE_ELEMENT_ACTIVE,
      payload: null,
    });
    setTimeout(() => {
      dispatch({
        type: MAKE_ELEMENT_ACTIVE,
        payload: elementId,
      });
    }, 0);
  };
}

export function changeProperties(location) {
  return dispatch => {
    dispatch({
      type: CHANGE_PROPERTIES,
      payload: location,
    });
  };
}

export function updateItem(id, item) {
  return dispatch => {
    dispatch({
      type: UPDATE_ELEMENT,
      payload: item,
      id,
    });
  };
}

export function dropElement(elementId) {
  return dispatch => {
    dispatch({
      type: DROP_ELEMENT,
      payload: elementId,
    });
  };
}

export function addField(object) {
  // return dispatch => dispatch({ type: TEMPLATE_EDITOR_FIELD_ADDED, payload: item });
  return dispatch => {
    dispatch({
      type: ADD_TO_SCENE,
      payload: { id: object.id, object },
    });
  };
}

export function itemSelected(item) {
  return dispatch => dispatch({ type: ITEM_SELECTED, payload: item });
}

export function itemDeleted(item) {
  return dispatch => dispatch({ type: ITEM_DELETED, payload: item });
}

export function resetReport(item) {
  return dispatch => dispatch({ type: RESET_REPORT });
}

export function searchMetric(search) {
  return dispatch => dispatch({ type: SEARCHING_GROUP_METRIC, payload: search });
}

export function getPentugramItems(id, url, field, search, extraFilter) {
  return dispatch => {
    dispatch(ThunkDebounced(id, url, field, search, extraFilter));
  };
}

const ThunkDebounced = debounceAction(ThunkItems, 1000, { leading: true });

function ThunkItems(id, url, field, search, extraFilter) {
  return dispatch => {
    dispatch({ type: PENTUGRAM_ITEMS_LOADED, payload: [] });
    let filter = `?filter[${field}][like]=${search}&per-page=8`;
    if (extraFilter) filter = `${filter}&filter[${extraFilter.field}]=${extraFilter.value}`;
    if (search)
      api
        .get(`/${url}${filter}`)
        .then(({ data }) => {
          dispatch({ type: PENTUGRAM_ITEMS_LOADED, payload: data.results });
        })
        .catch();
  };
}

export function updatePeriod(data) {
  return dispatch => dispatch({ type: PERIOD_UPDATED, payload: data });
}

export function getMetricData(item, metric_group_id, period) {
  return dispatch => {
    if (metric_group_id) {
      api
        .post('/reports/metrics', { metric_group_id, from: period.from, to: period.to })
        // .post("/reports/metrics", {metric_group_id, from: [9, 2019], to: [2, 2020] })
        .then(({ data }) => {
          dispatch({
            type: UPDATE_ELEMENT,
            payload: { ...item, data, reload_data: { can_update: true, type: 'metric', metric_group_id, period } },
            id: item.id,
          });
        })
        .catch(error => {
          Notifier({
            type: 'error',
            title: 'An error occured while loading metric data',
            message: '',
          });
        });
    }
  };
}

export function getPortfolioData(item, portfolio_id) {
  return dispatch => {
    api.get(`/reports/portfolio/${portfolio_id}`).then(({ data }) => {
      dispatch({
        type: UPDATE_ELEMENT,
        payload: {
          ...item,
          data,
          reload_data: {
            can_update: true,
            type: 'portfolio',
            portfolio_id,
          },
        },
        id: item.id,
      });
    });
  };
}

export function getDealData(item, deal_id) {
  return dispatch => {
    api.get(`/deals/${deal_id}/info`).then(({ data }) => {
      dispatch({
        type: UPDATE_ELEMENT,
        payload: {
          ...item,
          data,
          reload_data: {
            can_update: true,
            type: 'deal_card',
            deal_id,
          },
        },
        id: item.id,
      });
    });
  };
}

export function getContactData(item, contact_id) {
  return dispatch => {
    api.get(`/contacts/${contact_id}`).then(({ data }) => {
      dispatch({
        type: UPDATE_ELEMENT,
        payload: {
          ...item,
          data,
          reload_data: {
            can_update: true,
            type: 'contact_card',
            contact_id,
          },
        },
        id: item.id,
      });
    });
  };
}

export function addCover(cover = {}) {
  return dispatch => dispatch({ type: ADDED_COVER_PAEGE, payload: cover });
}

export function addSharedComponent(key, field, data) {
  return dispatch => dispatch({ type: SHARED_COMPONENT_ADDED, payload: { key, field, data } });
}

export function removeSharedComponent(item) {
  return dispatch => dispatch({ type: SHARED_COMPONENT_REMOVED, payload: item });
}

export function getPortfolioByType(portfolio_id, field_name, item) {
  return dispatch => {
    if (portfolio_id) {
      api
        .post(`/reports/portfolio/${portfolio_id}/pie`, { field_name })
        .then(({ data }) => {
          dispatch({
            type: UPDATE_ELEMENT,
            payload: {
              ...item,
              ...data,
              reload_data: {
                can_update: true,
                type: 'portfolio_pie',
                portfolio_id,
                field_name,
              },
            },
            id: item.id,
          });
        })
        .catch();
    }
  };
}

export function refreshReport({ from, to, reportId, period }) {
  return dispatch => {
    dispatch({ type: LOADING_CONTENT, payload: true });
    api
      .post(`/reports/update-data`, { from, to, reportId, period })
      .then(({ data }) => {
        dispatch({ type: LOADING_CONTENT, payload: false });
        dispatch({
          type: LOADED_REPORT,
          payload: data,
        });
        Notifier({
          type: 'open',
          title: 'Report updated successfully',
          message: '',
        });
      })
      .catch(e => {
        dispatch({ type: LOADING_CONTENT, payload: false });
      });
  };
}

export function updatePropertiesColor(field, color) {
  return dispatch => {
    dispatch({ type: UPDATE_GLOBAL_COLOR, field, payload: color });
  };
}

export function zoomInOut(zoom) {
  return dispatch => {
    dispatch({ type: ZOOM_IN_OUT, payload: zoom });
  };
}

export function removeComponent(element) {
  if (!element.shared) {
    return dispatch => {
      dispatch({
        type: DROP_ELEMENT,
        payload: element.id,
      });
    };
  }
  return dispatch => {
    switch (element.type) {
      case 'report_header':
        dispatch({ type: UPDATE_TOOLS, payload: { field: 'showHeader', value: false } });
        break;
      case 'report_footer':
        dispatch({ type: UPDATE_TOOLS, payload: { field: 'showFooter', value: false } });
        break;
      default:
        break;
    }
    return dispatch({ type: SHARED_COMPONENT_REMOVED, payload: element });
  };
}

export function togglePageOrientation(mode) {
  return dispatch => dispatch({ type: SWITCH_PAGE_ORIENTATION, payload: mode });
}

export function addToContentTable(item) {
  return dispatch => dispatch({ type: ADD_TO_CONTENT_TABLE, payload: item });
}

export function removeFromContentTable(id) {
  return dispatch => dispatch({ type: REMOVE_FROM_CONTENT_TABLE, payload: id });
}

export function sendElementToBack(item) {
  return dispatch => dispatch({ type: SEND_TO_BACK, payload: item });
}

export function sendElementToFront(item) {
  return dispatch => dispatch({ type: SEND_TO_FRONT, payload: item });
}

export function duplicatePage(content) {
  return dispatch => dispatch({ type: DUPLICATE_PAGE, payload: content });
}

export function addComment(comment, page) {
  return dispatch => dispatch({ type: ADD_REPORT_PAGE_COMMENT, payload: comment, page });
}

export function removeComment(commentId, page) {
  return dispatch => dispatch({ type: REMOVE_REPORT_PAGE_COMMENT, payload: commentId, page });
}

export function updateComment(newComment, page) {
  return dispatch => dispatch({ type: UPDATE_REPORT_PAGE_COMMENT, payload: newComment, page });
}

export function getPipelineCardData(item, metric, category, ids) {
  let pipelineDashboardMap = {
    deals_activity: 'number_open_deal_per_activity',
    deals_country: 'number_open_deal_per_country',
    deals_user: 'number_of_deal_per_user',
    deals_source: 'number_of_deals_per_source',
    deals_stage: 'funnel_chart_total_open_deal_per_stage',
    portfolios_deals: 'total_pipelines_deals',
  };

  let portfolioDashboardMap = {
    investment_activity: 'investment_per_activity',
    investment_country: 'investment_per_country',
    investment_round: 'investment_per_round',
    investment_stage: 'investment_per_stage',
    deals_round: 'number_of_deal_per_round',
    deals_stage: 'number_of_deal_per_stage',
    deals_user: 'number_of_deal_per_user',
    funds_table: 'funds_table',
    table_chart: 'table_chart',
    commitment_LP: 'commitment_per_LP',
    investment_fund: 'invested_fund',
    portfolios_deals: 'total_portfolios_deals',
  };

  if (item && metric && category && ids) {
    // portfolio_ids: ["7"]
    // pipeline_ids: ["4"]
    return dispatch => {
      let url = item.is_pipeline ? `/pipelines/chart-stats` : `portfolios/dashboard`;
      let isPipeline = item.is_pipeline ? 'pipeline_ids' : 'portfolio_ids';
      let mapKeys = item.is_pipeline ? pipelineDashboardMap : portfolioDashboardMap;
      let key = `${metric}_${category}`;
      api.post(url, { [isPipeline]: ids.split(','), data: [mapKeys[key]] }).then(({ data }) => {
        // filter data here;
        let values = data[mapKeys[key]];
        if (values) {
          dispatch({
            type: UPDATE_ELEMENT,
            payload: {
              ...item,
              data: values,
              metric,
              category,
              cascader_value: [metric, category],
              reload_data: {
                metric,
                category,
                can_update: true,
                type: 'dashboard_stats',
                ids,
              },
              metricId: key,
            },
            id: item.id,
          });
        }
      });
    };
  }
}

export function getDealRounds(item, deal_id, deal_name) {
  return dispatch => {
    if (item && deal_id && deal_name) {
      return api.get(`/deals/${deal_id}/rounds`).then(({ data }) => {
        let rounds = data.deal_rounds || [];
        if (rounds) {
          rounds = rounds.map(round => ({ ...round, name: round.stage, value: round.invested_amount, deal_name: round.name }));
          dispatch({
            type: UPDATE_ELEMENT,
            payload: {
              ...item,
              data: rounds,
              reload_data: {
                can_update: true,
                type: 'deal_rounds',
                deal_id: deal_id,
                deal_name: deal_name,
              },
              deal_name: deal_name,
              deal_id: deal_id,
            },
            id: item.id,
          });
        }
      });
    }
  };
}
export function updateSelectedItems(bounds) {
  return dispatch => dispatch({ type: SELECT_ITEMS, payload: bounds });
}

export function pasteSelectedItems() {
  return dispatch => dispatch({ type: PASTE_SELECTED_ITEMS });
}
export function clearSelectedItems() {
  return dispatch => dispatch({ type: CLEAR_SELECTED_ITEMS });
}

export function getScoreCardData(item, operator) {
  let { metric, pipeline_id } = item;
  if (item && metric && pipeline_id && operator) {
    // portfolio_ids: ["7"]
    // pipeline_ids: ["4"]
    return dispatch => {
      let url = 'pipelines/aggregation';
      api.post(url, { pipeline_ids: pipeline_id.split(','), aggregate: { field: metric, operator } }).then(({ data }) => {
        dispatch({
          type: UPDATE_ELEMENT,
          payload: {
            ...item,
            value: data[`${operator}_${metric}`],
            reload_data: {
              can_update: true,
              type: 'score_card',
              operator,
            },
            operation: operator,
          },
          id: item.id,
        });
      });
    };
  }
}
