import React from 'react';
import { Modal } from '../Modal/modal.component';
import { ModalIdEnum } from '../../../core/enums/entity-ids/modal-id.enum';
import { Button } from 'antd';
import './PipelineOpportunitiesInterestedModal.scss';
import { useDispatch } from 'react-redux';
import { closeModal } from '../Modal/state/modal.actions';

function PipelineOpportunitiesInterestedModal(props) {
  const dispatch = useDispatch();

  return (
    <Modal
      modalId={ModalIdEnum.pipelineOpportunitiesInterested}
      title='Pipeline Opportunities'
      width='40rem'
      hasTitle={true}
      onCancel={props.onClose}
      extraClasses='pipeline-opportunities-interested-modal'
    >
      <div className='pipeline-opportunities-interested-modal-container'>
        <div className='pipeline-opportunities-interested-modal-image'></div>
        <div className='pipeline-opportunities-interested-modal-content'>
          <div>
            <h3>We'll Be in Touch Soon!</h3>
            <p>Thank you for moving your card to the Interested column. Our team will review your information and contact you shortly to discuss the next steps.</p>
          </div>
        </div>
        <div className='pipeline-opportunities-interested-modal-footer'>
          <Button
            type='primary'
            style={{ backgroundColor: '#3070CC', borderColor: '#3070CC' }}
            size='large'
            onClick={() => {
              props.onClose();
              dispatch(closeModal());
            }}
          >
            Ok
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default PipelineOpportunitiesInterestedModal;
