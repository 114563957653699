import { ANIM_SIDEMENU_HIGHTLIGHT } from '../actions/animActions';

const initialState = {
  hightlighted: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ANIM_SIDEMENU_HIGHTLIGHT: {
      return {
        ...state,
        hightlighted: action.payload,
      };
    }
  }
  return state;
}
